import {
  useForm,
  VtxButton,
  VtxCol,
  VtxForm,
  VtxFormItem,
  VtxInput,
  VtxModal,
  VtxRow,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useState } from 'react';
import { EMAIL_DOMAIN_REGEX } from 'src/constants';

interface OwnProps {
  onModalOk: (value: string) => void;
  onModalCancel: () => void;
}

interface IDomainForm {
  domain: string;
}

const DEFAULT_MAX_LENGTH = 50;

export const CustomerEmailDomainAddModal = (props: OwnProps) => {
  const { onModalOk, onModalCancel } = props;
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const [form] = useForm<IDomainForm>({ domain: '' });

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleCancel = () => {
    if (form.isFieldTouched('domain')) {
      setIsFormDirty(true);
    } else if (!isFormDirty) {
      onModalCancel();
    }
  };

  const onFinish = (form: IDomainForm) => {
    onModalOk(form.domain);
  };

  return (
    <>
      <VtxModal
        title="Add Email Domain"
        data-testid="add-email-domain-modal"
        visible
        closable
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <VtxButton type="primary" data-automation-id="add-modal-save-btn" onClick={handleSubmit} key="ok">
            Save
          </VtxButton>,
          <VtxButton data-automation-id="add-modal-cancel-btn" onClick={handleCancel} key="cancel">
            Cancel
          </VtxButton>,
        ]}
      >
        <VtxRow>
          <VtxCol lg={50} xs={100} style={{ width: '20rem', maxWidth: '100%' }}>
            <VtxForm<IDomainForm>
              form={form}
              name="add-domain-form"
              onFinish={onFinish}
              validateTrigger="onBlur"
            >
              <VtxFormItem<IDomainForm>
                name="domain"
                label="Email Domain"
                rules={[
                  { max: DEFAULT_MAX_LENGTH, message: 'Email domain cannot exceed 50 characters.' },
                  { required: true, message: 'Email domain is required.' },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (value && !EMAIL_DOMAIN_REGEX.test(value)) {
                        return Promise.reject(
                          "Email domain must be in the format '@email.com' and contain only lower-case letters, numbers, or -."
                        );
                      }
                      if (value.length > DEFAULT_MAX_LENGTH) {
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <VtxInput data-testid="email-domain-name" />
              </VtxFormItem>
            </VtxForm>
          </VtxCol>
        </VtxRow>
      </VtxModal>
      {isFormDirty && (
        <VtxModal
          title="Do you want to leave this form?"
          data-testid="confirmation-close-modal"
          onCancel={() => setIsFormDirty(false)}
          onOk={onModalCancel}
          cancelText="No"
          okText="Yes"
          cancelButtonProps={{ 'data-automation-id': 'confirmation-close-modal-cancel-btn' }}
          okButtonProps={{ 'data-automation-id': 'confirmation-close-modal-ok-btn' }}
          visible
        >
          Any unsaved data will be lost.
        </VtxModal>
      )}
    </>
  );
};
