import { FormInstanceStrict } from '@vertexinc/vtx-ui-react-component-library';

export const HelperValues = {
  EMPTY_STRING: '',
  ZERO: 0,
};

interface DirtyFieldsProps<T> {
  fieldArray: Array<keyof T>;
  form: FormInstanceStrict<T>;
}

export const getIsDirtyForm = <T,>(props: DirtyFieldsProps<T>) => {
  const { fieldArray, form } = props;
  const isTouched = fieldArray.filter((field) => form.isFieldTouched(field as string));
  return isTouched && isTouched.length > HelperValues.ZERO;
};
