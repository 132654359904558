import { User, UserManager } from 'oidc-client-ts';
import { PATHS } from 'src/constants';
import { IOrganization } from 'src/models/organization';
import { SessionManager } from 'src/session-manager';
import { getLocationPathname, getLocationSearch, setLocationHref } from '../dom-helpers/window-wrapper';
import { getOrgFromId, getOrgIdFromJwt, getOrgIdFromName, getOrgNameFromUrl } from './get-organization';

export type UserOrRefreshingOrSessionEnded = User | 'currentlyRefreshing' | 'sessionEnded' | 'inError';

export type OrganizationOrInvalid = IOrganization | null;

const pathStartsWith = (expectedPrefix: string): boolean => {
  const pathNameLower = getLocationPathname().toLowerCase();
  return pathNameLower === expectedPrefix || pathNameLower.startsWith(`${expectedPrefix}/`);
};

const pathEqualsPrefix = (expectedPrefix: string): boolean => {
  const pathNameLower = getLocationPathname().toLowerCase();
  return pathNameLower === expectedPrefix;
};

export const authorize = async (
  sessionManager: SessionManager,
  userManager: UserManager
): Promise<{
  userOrRefreshing: UserOrRefreshingOrSessionEnded;
  organization: OrganizationOrInvalid;
}> => {
  const isUrlSpaRoot = pathEqualsPrefix(PATHS.ROOT);

  // If auth callback request, handle callback
  const isUrlAuthCallback = pathEqualsPrefix(PATHS.OIDC_REDIRECT_RELATIVE);
  if (isUrlAuthCallback) {
    const user = await userManager.signinCallback();
    if (user) {
      const { state: redirectUrl, access_token } = user;
      if (redirectUrl != null && typeof redirectUrl === 'string') {
        setLocationHref(redirectUrl);
        return { userOrRefreshing: 'currentlyRefreshing', organization: null };
      } else {
        // If no redirect URL, get org ID from JWT, then make API call to get org name, then
        // update pathname to include org name.
        const orgId = getOrgIdFromJwt(access_token);
        if (!orgId) {
          setLocationHref(PATHS.ERROR);
          return { userOrRefreshing: 'inError', organization: null };
        } else {
          // Set access token on Session Manager so we can make API call to get org by ID
          sessionManager.setAccessToken(access_token);

          const org = await getOrgFromId(orgId);
          if (!org) {
            setLocationHref(PATHS.ERROR);
            return { userOrRefreshing: 'inError', organization: null };
          } else {
            setLocationHref(`/${org.name}`);
            return { userOrRefreshing: 'currentlyRefreshing', organization: null };
          }
        }
      }
    } else {
      setLocationHref(PATHS.ERROR);
      return { userOrRefreshing: 'inError', organization: null };
    }
  }

  if (pathStartsWith(PATHS.LOGGED_OUT_ROUTE)) {
    return { userOrRefreshing: 'sessionEnded', organization: null };
  }

  const isUrlErrorPage =
    pathStartsWith(PATHS.ERROR) || pathStartsWith(PATHS.FORBIDDEN) || pathStartsWith(PATHS.NOT_FOUND);

  // Check if access token exists and hasn't expired.
  const orgNameFromUrl = getOrgNameFromUrl();
  const user = await userManager.getUser();
  if (user == null || user.expired) {
    if (isUrlErrorPage) {
      return { userOrRefreshing: 'inError', organization: null };
    }

    // If no valid session and no org in pathname, set org as invalid
    if (isUrlSpaRoot || !orgNameFromUrl) {
      setLocationHref(PATHS.ERROR);
      return { userOrRefreshing: 'inError', organization: null };
    }
    // Then make API call to get org ID
    const orgId = await getOrgIdFromName(orgNameFromUrl);
    if (!orgId) {
      setLocationHref(PATHS.ERROR);
      return { userOrRefreshing: 'inError', organization: null };
    }

    // Set attempted URL to state so it will be navigated to after authentication
    let redirectUrl = getLocationPathname();
    const queryString = getLocationSearch();
    if (!!queryString) {
      redirectUrl += queryString;
    }

    // Redirect to auth server
    await userManager.signinRedirect({
      state: redirectUrl,
      extraQueryParams: {
        ...userManager.settings.extraQueryParams,
        organization: orgId,
      },
    });
    return { userOrRefreshing: 'currentlyRefreshing', organization: null };
  }

  // If valid user session, determine if there is a need to redirect
  const orgId = getOrgIdFromJwt(user.access_token);
  if (!orgId) {
    setLocationHref(PATHS.ERROR);
    return { userOrRefreshing: 'inError', organization: null };
  }
  // Set access token on Session Manager so we can make API call to get org by ID
  sessionManager.setAccessToken(user.access_token);

  const org = await getOrgFromId(orgId);
  if (!org) {
    setLocationHref(PATHS.ERROR);
    return { userOrRefreshing: 'inError', organization: null };
  }

  if (isUrlErrorPage) {
    return { userOrRefreshing: 'inError', organization: org };
  }

  // If valid user, but no org in pathname, redirect to path with name of org from JWT.
  if (isUrlSpaRoot || org.name !== orgNameFromUrl) {
    setLocationHref(`/${org.name}`);
    return { userOrRefreshing: 'currentlyRefreshing', organization: org };
  }

  return { userOrRefreshing: user, organization: org };
};
