import { VtxErrorSection } from '@vertexinc/vtx-ui-react-component-library';
import * as React from 'react';
import { OrganizationOrInvalid } from 'src/auth/authorize';
import { PUBLIC_APP_URL } from 'src/constants';

interface OwnProps {
  organization: OrganizationOrInvalid;
}

export const AccessDenied = (props: OwnProps) => {
  const { organization } = props;
  const url = organization ? `/${organization.name}` : PUBLIC_APP_URL;
  return <VtxErrorSection data-testid="error-page-403" errorType="403" callToActionElement={{ url: url }} />;
};
