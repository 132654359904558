import { getPaginatedRequestParams, request } from 'src/api/request';
import { ICustomer, ICustomerAdd, IGetCustomersParams } from 'src/models/customer';
import { PaginatedDataset } from 'src/models/paginated-dataset';
import { IUserRole } from 'src/models/user-info';
import { getWrappedProcessEnv } from 'src/wrapped-process-env';

const baseUrl = `${getWrappedProcessEnv().API_BASE_URL}/customers`;

const customerService = () => {
  return {
    getCustomers: async (params: IGetCustomersParams): Promise<PaginatedDataset<ICustomer>> => {
      const requestParams = getPaginatedRequestParams(params);
      return request<PaginatedDataset<ICustomer>>(baseUrl).params(requestParams).get();
    },
    getCustomer: async (params: { id: string }): Promise<ICustomer> => {
      return request<ICustomer>(`${baseUrl}/${params.id}`).get();
    },
    getCustomerRoles: async (params: { id: string }): Promise<IUserRole[]> => {
      return request<IUserRole[]>(`${baseUrl}/${params.id}/roles`).get();
    },
    addCustomer: async (params: { customer: ICustomerAdd }): Promise<ICustomer> => {
      const { customer } = params;
      return request<ICustomer>(`${baseUrl}/invite`).post<ICustomerAdd>(customer);
    },
    deleteCustomer: async (params: { customerId: string; versionId: string }): Promise<ICustomer> => {
      return request<ICustomer>(`${baseUrl}/${params.customerId}/${params.versionId}`).delete();
    },
  };
};

export const CustomerService = customerService();
