import {
  VtxIcon,
  VtxSelect,
  VtxSelectComponents,
  VtxSelectOptionProps,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { FunctionComponent } from 'react';
import { IUserInfo } from 'src/models/user-info';

const LOGOUT_VALUE = 'logout';
const DEFAULT_DISPLAY_NAME = 'Vertex User';

interface OwnProps {
  userInfo: IUserInfo;
  logOut: () => Promise<void>;
}

interface UserInfoOption {
  label: string;
  value: string;
}

export const UserInfo: FunctionComponent<OwnProps> = (props) => {
  const { userInfo, logOut } = props;
  const displayName =
    userInfo.firstName || userInfo.lastName
      ? `${userInfo.firstName || ''} ${userInfo.lastName || ''}`.trim()
      : DEFAULT_DISPLAY_NAME;

  const userInfoOptions: UserInfoOption[] = [{ label: 'Log Out', value: LOGOUT_VALUE }];
  const userInfoOptionDropdown = ({ children, ...props }: VtxSelectOptionProps<UserInfoOption>) => {
    return (
      <VtxSelectComponents.Option {...props}>
        <span className="user-info-log-out-container">
          <VtxIcon className="user-info-log-out-icon" fontSize="1.143em" role="presentation" type="logout" />
        </span>
        {props.data.label}
      </VtxSelectComponents.Option>
    );
  };

  return (
    <div className="user-info-container">
      <VtxSelect<UserInfoOption>
        id="user-info-drop-down"
        className="user-info-drop-down"
        data-testid="user-profile-select"
        aria-label="User Info"
        onChange={() => logOut()}
        isSearchable={false}
        options={userInfoOptions}
        placeholder={displayName}
        controlShouldRenderValue={false}
        components={{ Option: userInfoOptionDropdown }}
      />
    </div>
  );
};
