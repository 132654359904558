import { VtxErrorSection } from '@vertexinc/vtx-ui-react-component-library';
import * as React from 'react';
import { useEffect } from 'react';
import { OrganizationOrInvalid } from 'src/auth/authorize';
import { PUBLIC_APP_URL } from 'src/constants';

interface OwnProps {
  organization: OrganizationOrInvalid;
  showSideMenu?: (visible: boolean) => void;
}

export const NotFound = (props: OwnProps) => {
  const { organization, showSideMenu } = props;

  useEffect(() => {
    if (showSideMenu) {
      showSideMenu(false);
      return () => showSideMenu(true);
    } else {
      return () => null;
    }
  }, [showSideMenu]);

  const url = organization ? `/${organization.name}` : PUBLIC_APP_URL;
  return <VtxErrorSection errorType="404" data-testid="error-page-404" callToActionElement={{ url: url }} />;
};
