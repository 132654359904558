import { VtxButton } from '@vertexinc/vtx-ui-react-component-library';
import { parse } from 'query-string';
import * as React from 'react';
import loggedOutImage from 'src/images/logout.svg';
import 'src/pages/logged-out/logged-out.scss';

interface OwnProps {
  redirectToLogin: (orgId: string) => void;
}

export const LoggedOut = ({ redirectToLogin }: OwnProps) => {
  const { orgId, timeout } = parse(window.location.search);
  const isTimeOut = timeout === 'true';
  const organizationId = typeof orgId === 'string' ? orgId : undefined;
  const message = isTimeOut ? 'Whoops, your session timed out' : 'Logged out successfully';
  const additionalMessage = isTimeOut
    ? 'Please log in again to continue'
    : 'Forgot an item from your to-do list?';

  return (
    <div>
      <div className="logged-out-image">
        <img src={loggedOutImage} alt="Logged Out" />
      </div>
      <div className="logged-out-message" data-testid="logged-out-message">
        {message}
      </div>
      <p className="logged-out-additional-message" data-testid="logged-out-additional-message">
        {additionalMessage}
      </p>
      <div className="logged-out-login">
        {organizationId ? (
          <VtxButton
            type="primary"
            onClick={() => redirectToLogin(organizationId)}
            data-testid="page-login-button"
          >
            Log Back In
          </VtxButton>
        ) : (
          <div data-testid="logged-out-instructions">
            Log back in by entering your Vertex enterprise single sign-on URL. For assistance, please contact
            Vertex Support.
          </div>
        )}
      </div>
    </div>
  );
};
