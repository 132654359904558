import { AxiosError } from 'axios';
import { UserOrRefreshingOrSessionEnded } from './auth/authorize';
import { IUserInfo } from './models/user-info';

export interface ICustomerRouteParams {
  customerId: string;
}

export interface IConnectionRouteParams {
  connectionId: string;
}

export enum SortingDirection {
  Desc = 'desc',
  Asc = 'asc',
  Descend = 'descend',
  Ascend = 'ascend',
}

export interface IError {
  cause?: AxiosError;
}

export interface StatefulMessage {
  text: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

export interface ILoginUser {
  user: UserOrRefreshingOrSessionEnded;
  userInfo: IUserInfo;
}

export type FormAction = 'add' | 'edit';
