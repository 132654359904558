import {
  VtxAlert,
  VtxButton,
  VtxColumnProps,
  VtxSpace,
  VtxTable,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { VodSubDomainsService } from 'src/api/vod-sub-domains';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { ActionConfirmationModal } from 'src/components/modals/action-confirmation-modal';
import { QUERY_KEYS } from 'src/constants';
import { ICustomer, IVodSubDomain, IVodSubDomainAdd } from 'src/models/customer';
import { IUserInfo } from 'src/models/user-info';
import { CustomerVodAddModal } from 'src/pages/customer-management/details/vod-sub-domains/customer-vod-add-modal';
import { SharedCustomerSearchPanel } from 'src/pages/customer-management/shared-customer-search-panel';
import { ICustomerRouteParams, IError, StatefulMessage } from 'src/types';
import { isVertexAdmin } from 'src/utils/role-helper';
import { alphabeticalSort } from 'src/utils/table-helper';

interface OwnProps {
  userInfo: IUserInfo;
  customer: ICustomer;
  vodSubDomains?: IVodSubDomain[];
  isLoading: boolean;
}

export const CustomerVodSubDomains = (props: OwnProps) => {
  const { userInfo, customer, vodSubDomains, isLoading } = props;
  const { customerId }: ICustomerRouteParams = useParams();
  const queryClient = useQueryClient();
  const [subDomainsData, setSubDomainsData] = useState<IVodSubDomain[] | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currentSubDomain, setCurrentSubDomain] = useState<IVodSubDomain>();
  const [message, setMessage] = useState<StatefulMessage>();
  const [error, setError] = useState();
  const generalErrorMessageOnAdd = 'The record could not be created.';
  const generalErrorMessageOnDelete = 'The record could not be deleted.';
  const isUserVertexAdmin = isVertexAdmin(userInfo);

  const renderAction = (id: IVodSubDomain['customerId'], record: IVodSubDomain) => {
    return (
      <VtxButton
        type="danger"
        data-testid={`delete-subdomain-${record.subDomain}`}
        aria-label={`Delete ${record.subDomain}`}
        onClick={() => handleDelete(record)}
        disabled={isSubmitting}
      >
        Delete
      </VtxButton>
    );
  };

  const columns: VtxColumnProps<IVodSubDomain>[] = [
    {
      title: 'VOD Sub-Domains',
      dataIndex: 'subDomain',
      sorter: (a: IVodSubDomain, b: IVodSubDomain) => alphabeticalSort(a.subDomain, b.subDomain),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 250,
    },
  ];

  if (isUserVertexAdmin) {
    columns.push({
      title: 'Actions',
      dataIndex: 'customerId',
      render: renderAction,
    });
  }

  const deleteVodSubDomain = useMutation(VodSubDomainsService.deleteVodSubDomain, {
    onSuccess: () => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setMessage({
        type: 'success',
        text: `The sub-domain ${currentSubDomain?.subDomain} was successfully deleted.`,
      });
      setCurrentSubDomain(undefined);
      setSearchValue('');
      queryClient.invalidateQueries([QUERY_KEYS.VOD_SUBDOMAINS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnDelete);
    },
  });

  const addVodSubDomain = useMutation(VodSubDomainsService.addVodSubDomain, {
    onSuccess: (_, variables) => {
      setIsSubmitting(false);
      setIsAddModalVisible(false);
      setMessage({
        type: 'success',
        text: `The new sub-domain ${variables.params.vodSubDomains.join()} was successfully created.`,
      });
      setSearchValue('');
      queryClient.invalidateQueries([QUERY_KEYS.VOD_SUBDOMAINS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsAddModalVisible(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnAdd);
    },
  });

  const handleDelete = (subDomain: IVodSubDomain) => {
    setIsSubmitting(true);
    setCurrentSubDomain(subDomain);
    setIsDeleteModalVisible(true);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (!value) {
      setSubDomainsData(vodSubDomains);
    } else {
      const searchResult = vodSubDomains?.filter((domain: IVodSubDomain) => {
        return domain.subDomain.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      setSubDomainsData(searchResult);
    }
  };

  const handleAdd = () => {
    setIsSubmitting(true);
    setIsAddModalVisible(true);
  };

  const onDeleteOk = () => {
    deleteVodSubDomain.mutate({
      customerId,
      vodSubDomain: currentSubDomain?.subDomain || '',
      customerVersionId: customer.versionId,
    });
  };

  const onDeleteClose = () => {
    setCurrentSubDomain(undefined);
    setIsDeleteModalVisible(false);
    setIsSubmitting(false);
  };

  const onAddOk = (domain: string) => {
    const params: IVodSubDomainAdd = {
      customerVersionId: customer.versionId,
      vodSubDomains: [domain],
    };
    addVodSubDomain.mutate({ customerId, params });
  };

  const onAddClose = () => {
    setIsAddModalVisible(false);
    setIsSubmitting(false);
  };

  useEffect(() => setSubDomainsData(vodSubDomains), [vodSubDomains]);

  return (
    <>
      <VtxSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
        {error && (
          <ErrorAlert
            errors={error}
            onClose={() => setError(undefined)}
            message={
              error === generalErrorMessageOnAdd
                ? generalErrorMessageOnAdd
                : error === generalErrorMessageOnDelete
                ? generalErrorMessageOnDelete
                : undefined
            }
          />
        )}
        {message && (
          <VtxAlert
            type={message.type}
            message={message.text}
            closable
            onClose={() => setMessage(undefined)}
            data-testid="customers-vod-subdomains-alert"
          />
        )}
        <SharedCustomerSearchPanel
          onSearch={handleSearch}
          onActionButtonClick={handleAdd}
          actionButtonText="Add VOD Sub-Domain"
          shouldDisplayActionButton={isUserVertexAdmin}
          searchValue={searchValue}
          searchAriaLabel="Search for VOD Sub-Domains"
          dataTestIdSuffix="vod-sub-domains"
        />
        <VtxTable<IVodSubDomain>
          rowKey="auth0ClientId"
          columns={columns}
          loading={isLoading}
          dataSource={subDomainsData}
          pagination={false}
          data-testid="customer-vod-subdomains-table"
        />
      </VtxSpace>
      {isAddModalVisible && <CustomerVodAddModal onModalOk={onAddOk} onModalCancel={onAddClose} />}
      {isDeleteModalVisible && (
        <ActionConfirmationModal
          onCancel={onDeleteClose}
          onConfirm={onDeleteOk}
          title={`Do you want to delete the Sub-Domain ${currentSubDomain?.subDomain}`}
          description={`Deleting the VOD Sub-Domain ${currentSubDomain?.subDomain} cannot be undone. Do you want to delete it?`}
          isVisible={isDeleteModalVisible}
        />
      )}
    </>
  );
};
