import { useBreakpoint } from '@vertexinc/vtx-ui-react-component-library';
import React, { useState } from 'react';
import { BrowserRouter as Router, generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { OrganizationOrInvalid } from './auth/authorize';
import { PATHS } from './constants';
import { Header } from './header/header';
import { IUserInfo } from './models/user-info';
import { CustomerManagement } from './pages/customer-management/customer-management';
import { AccessDenied } from './pages/errors/access-denied';
import { Error } from './pages/errors/error';
import { NotFound } from './pages/errors/not-found';
import { LoggedOut } from './pages/logged-out/logged-out';
import { SideMenu } from './side-menu/side-menu';
import { isVertexAdmin } from './utils/role-helper';

interface OwnProps {
  userInfo: IUserInfo | null;
  organization: OrganizationOrInvalid;
  redirectToLogin: (orgId: string) => Promise<void>;
}

export const Routes = (props: OwnProps) => {
  const { userInfo, organization, redirectToLogin } = props;
  const { lg } = useBreakpoint();
  const [isMenuOpen, setIsMenuOpen] = useState(lg);
  const [hasSideMenu, setHasSideMenu] = useState<boolean>(true);
  const isUserAuthenticated = !!organization && userInfo != null;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <Header
        isMenuOpen={isMenuOpen}
        hasSideMenu={isUserAuthenticated && hasSideMenu}
        toggleMenu={toggleMenu}
        userInfo={userInfo}
        redirectToLogin={redirectToLogin}
        organization={organization}
      />
      {isUserAuthenticated && hasSideMenu && <SideMenu userInfo={userInfo} organization={organization} />}
      <div
        id="app-content"
        className={userInfo && hasSideMenu ? 'app-container' : 'app-container app-container__error'}
      >
        <Switch>
          {isUserAuthenticated && (
            <Route
              path={PATHS.ORGANIZATION}
              render={() => {
                const { customerId, name: orgName } = organization;
                const redirectPath = isVertexAdmin(userInfo)
                  ? generatePath(PATHS.CUSTOMER_INDEX, { orgName })
                  : generatePath(PATHS.CUSTOMER_VIEW, { orgName, customerId });
                return <Redirect to={redirectPath} />;
              }}
              exact
            />
          )}
          {isUserAuthenticated && (
            <Route path={PATHS.CUSTOMER_INDEX}>
              <CustomerManagement userInfo={userInfo} organization={organization} />
            </Route>
          )}
          <Route path={PATHS.ERROR}>
            <Error organization={organization} />
          </Route>
          <Route path={PATHS.FORBIDDEN}>
            <AccessDenied organization={organization} />
          </Route>
          <Route path={PATHS.LOGGED_OUT_ROUTE}>
            <LoggedOut redirectToLogin={redirectToLogin} />
          </Route>
          <Route path="*">
            <NotFound organization={organization} showSideMenu={setHasSideMenu} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
