import {IEnterpriseConnection, IEnterpriseConnectionAdd, IEnterpriseConnectionEdit} from '../models/customer';
import { getWrappedProcessEnv } from '../wrapped-process-env';
import { request } from './request';

const baseUrl = `${getWrappedProcessEnv().API_BASE_URL}/customers`;
const enterpriseConnectionsService = () => {
  return {
    getConnections: async (props: { customerId: string }): Promise<IEnterpriseConnection[]> => {
      const { customerId } = props;
      return request<IEnterpriseConnection[]>(`${baseUrl}/${customerId}/connections`).get();
    },
    getConnection: async (props: {
      customerId: string;
      connectionId: string;
    }): Promise<IEnterpriseConnection> => {
      const { customerId, connectionId } = props;
      return request<IEnterpriseConnection>(`${baseUrl}/${customerId}/connections/${connectionId}`).get();
    },
    addConnection: async (props: {
      customerId: string;
      connection: IEnterpriseConnectionAdd;
    }): Promise<IEnterpriseConnection> => {
      const { customerId, connection } = props;
      return request<IEnterpriseConnection>(`${baseUrl}/${customerId}/connections`).post(connection);
    },
    editConnection: async (props: {
      customerId: string;
      connection: IEnterpriseConnectionEdit;
    }): Promise<IEnterpriseConnection> => {
      const { customerId, connection } = props;
      return request<IEnterpriseConnection>(
        `${baseUrl}/${customerId}/connections/${connection.enterpriseConnectionId}`
      ).put(connection);
    },
    deleteConnection: async (props: { customerId: string; connectionId: string }): Promise<null> => {
      const { customerId, connectionId } = props;
      return request<null>(`${baseUrl}/${customerId}/connections/${connectionId}`).delete();
    },
  };
};

export const EnterpriseConnectionsService = enterpriseConnectionsService();
