import { getPaginatedRequestParams, request } from 'src/api/request';
import { PaginatedDataset } from 'src/models/paginated-dataset';
import { IGetUsersParams, IUser, IUserAdd, IUserEdit } from 'src/models/user';
import { getWrappedProcessEnv } from 'src/wrapped-process-env';

const baseUrl = `${getWrappedProcessEnv().API_BASE_URL}/customers`;
const usersService = () => {
  return {
    getUsers: async (props: {
      customerId: string;
      params: IGetUsersParams;
    }): Promise<PaginatedDataset<IUser>> => {
      const { customerId, params } = props;
      const requestParams = getPaginatedRequestParams(params);
      return request<PaginatedDataset<IUser>>(
        `${getWrappedProcessEnv().API_BASE_URL}/customerusers/${customerId}`
      )
        .params(requestParams)
        .get();
    },
    addUser: async (props: { customerId: string; params: IUserAdd }): Promise<IUser> => {
      const { customerId, params } = props;
      return request<IUser>(`${baseUrl}/${customerId}/users`).post(params);
    },
    editUser: async (props: { customerId: string; params: IUserEdit }): Promise<IUser> => {
      const { customerId, params } = props;
      return request<IUser>(`${baseUrl}/${customerId}/users`).put(params);
    },
    deleteUser: async (props: {
      customerId: string;
      userId: string;
      customerVersionId: string;
    }): Promise<null> => {
      const { customerId, userId, customerVersionId } = props;
      return request<null>(`${baseUrl}/${customerId}/users/${userId}/${customerVersionId}`).delete();
    },
  };
};

export const UsersService = usersService();
