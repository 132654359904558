import { VtxModal } from '@vertexinc/vtx-ui-react-component-library';
import React from 'react';
import NavigationPrompt, { NavigationPromptProps } from 'react-router-navigation-prompt';

interface INavigationConfirmationModal {
  when: NavigationPromptProps['when'];
  modalTitle?: string;
  modalDescription?: string;
}

interface INavigationConfirmationActions {
  onConfirm: () => void;
  onCancel: () => void;
}

const DEFAULT_MODAL_TITLE = 'Do you want to leave this page?';
const DEFAULT_MODAL_DESCRIPTION = 'Any unsaved data will be lost.';

export const NavigationConfirmationModal = ({
  when,
  modalTitle = DEFAULT_MODAL_TITLE,
  modalDescription = DEFAULT_MODAL_DESCRIPTION,
}: INavigationConfirmationModal) => {
  return (
    <div>
      <NavigationPrompt when={when}>
        {({ onConfirm, onCancel }: INavigationConfirmationActions) => (
          <VtxModal
            title={modalTitle}
            data-testid="navigation-confirmation-modal"
            onCancel={onCancel}
            onOk={onConfirm}
            cancelText="No"
            okText="Yes"
            cancelButtonProps={{ 'data-automation-id': 'navigation-confirmation-modal-cancel-btn' }}
            okButtonProps={{ 'data-automation-id': 'navigation-confirmation-modal-ok-btn' }}
            visible
          >
            {modalDescription}
          </VtxModal>
        )}
      </NavigationPrompt>
    </div>
  );
};
