import { request } from 'src/api/request';
import { IEmailDomain, IEmailDomainAdd } from 'src/models/customer';
import { getWrappedProcessEnv } from 'src/wrapped-process-env';

const baseUrl = `${getWrappedProcessEnv().API_BASE_URL}/customers`;
const emailDomainsService = () => {
  return {
    getEmailDomains: async (props: {
      customerId: string;
    }): Promise<IEmailDomain[]> => {
      const { customerId } = props;
      return request<IEmailDomain[]>(`${baseUrl}/${customerId}/emaildomains`).get();
    },
    addEmailDomain: async (props: { customerId: string; params: IEmailDomainAdd }): Promise<IEmailDomain> => {
      const { customerId, params } = props;
      return request<IEmailDomain>(`${baseUrl}/${customerId}/emaildomains`).post(params);
    },
    deleteEmailDomain: async (props: {
      customerId: string;
      emailDomain: string;
      customerVersionId: string;
    }): Promise<null> => {
      const { customerId, emailDomain, customerVersionId } = props;
      return request<null>(
        `${baseUrl}/${customerId}/emaildomains/${emailDomain}/${customerVersionId}`
      ).delete();
    },
  };
};

export const EmailDomainsService = emailDomainsService();
