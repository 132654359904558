import {
  VtxBreadcrumb,
  VtxBreadcrumbItem,
  VtxLoadingIndicator,
  VtxTab,
  VtxTabList,
  VtxTabPanel,
  VtxTabPanels,
  VtxTabs,
  VtxTitle,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useEffect, useState } from 'react';
import { useIsFetching, useQuery, useQueryClient } from 'react-query';
import { generatePath, Link, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { EnterpriseConnectionsService } from 'src/api/connections';
import { UsersService } from 'src/api/users';
import { DEFAULT_PAGE_SIZE, PATHS, QUERY_KEYS } from 'src/constants';
import { ICustomer, IEmailDomain, IEnterpriseConnection, IVodSubDomain } from 'src/models/customer';
import { IOrganization } from 'src/models/organization';
import { PaginatedDataset } from 'src/models/paginated-dataset';
import { IUser } from 'src/models/user';
import { IUserInfo, IUserRole } from 'src/models/user-info';
import { EnterpriseConnections } from 'src/pages/customer-management/details/connections/enterprise-connections';
import { CustomerOwnedEmailDomains } from 'src/pages/customer-management/details/customer-owned-email-domains/customer-owned-email-domains';
import { CustomerUsers } from 'src/pages/customer-management/details/users/customer-users';
import { CustomerVodSubDomains } from 'src/pages/customer-management/details/vod-sub-domains/customer-vod-sub-domains';
import { ICustomerRouteParams, SortingDirection } from 'src/types';

enum Tab {
  CustomerOwnedEmailDomains = 'Customer-Owned Email Domains',
  VODSubDomains = 'VOD Sub-Domains',
  Users = 'Users',
  Connections = 'Connections',
}

interface OwnProps {
  userInfo: IUserInfo;
  organization: IOrganization;
}

export const CustomerDetailsViewPage = (props: OwnProps) => {
  const {
    organization: { name: orgName },
    userInfo,
  } = props;
  const { customerId }: ICustomerRouteParams = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>();
  const { pathname } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const isVodSubDomainsLoading = useIsFetching(QUERY_KEYS.VOD_SUBDOMAINS);
  const isEmailDomainsLoading = useIsFetching(QUERY_KEYS.EMAIL_DOMAINS);

  const customerOwnedEmailDomainsTabPath = generatePath(PATHS.CUSTOMER_VIEW, { orgName, customerId });
  const vodSubDomainsTabPath = generatePath(PATHS.CUSTOMER_VIEW_VOD_DOMAINS, { orgName, customerId });
  const usersTabPath = generatePath(PATHS.CUSTOMER_VIEW_USERS, { orgName, customerId });
  const connectionsTabPath = generatePath(PATHS.CUSTOMER_VIEW_CONNECTIONS, { orgName, customerId });

  const [usersCurrentPage, setUsersCurrentPage] = useState<number>(1);
  const [usersPageSize, setUsersPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [usersSorting, setUsersSorting] = useState<string>(`user/emailAddress ${SortingDirection.Asc}`);
  const [usersSearchValue, setUsersSearchValue] = useState<string>('');

  const customer = queryClient.getQueryData<ICustomer>([QUERY_KEYS.CUSTOMER, customerId]);
  const vodSubDomains = queryClient.getQueryData<IVodSubDomain[]>([QUERY_KEYS.VOD_SUBDOMAINS]) || [];
  const emailDomains = queryClient.getQueryData<IEmailDomain[]>([QUERY_KEYS.EMAIL_DOMAINS]) || [];
  const customerRoles = queryClient.getQueryData<IUserRole[]>([QUERY_KEYS.CUSTOMER_ROLES, customerId]) || [];

  const usersQuery = useQuery<PaginatedDataset<IUser>, Error>(
    [QUERY_KEYS.USERS, usersCurrentPage, usersPageSize, usersSorting, usersSearchValue],
    () =>
      UsersService.getUsers({
        customerId,
        params: {
          currentPage: usersCurrentPage,
          pageSize: usersPageSize,
          orderBy: usersSorting,
          search: usersSearchValue,
          expand: 'User',
        },
      }),
    {
      onError: (err: Error) => {
        throw new Error(
          'An error occurred retrieving customer information. Please try again and contact support if the problem persists.'
        );
      },
    }
  );

  const enterpriseConnectionsQuery = useQuery<IEnterpriseConnection[], Error>(
    [QUERY_KEYS.ENTERPRISE_CONNECTIONS],
    () => EnterpriseConnectionsService.getConnections({ customerId }),
    {
      onError: (err: Error) => {
        throw err;
      },
    }
  );

  const changeTab = (key: number) => {
    setActiveTabIndex(key);
    switch (key) {
      case 1:
        history.push(vodSubDomainsTabPath);
        break;
      case 2:
        history.push(usersTabPath);
        break;
      case 3:
        history.push(connectionsTabPath);
        break;
      default:
        history.push(customerOwnedEmailDomainsTabPath);
        break;
    }
  };

  useEffect(() => {
    let index = 0;
    if (pathname === vodSubDomainsTabPath) {
      index = 1;
    } else if (pathname === usersTabPath) {
      index = 2;
    } else if (pathname === connectionsTabPath) {
      index = 3;
    }
    setActiveTabIndex(index);
  }, [pathname, customerId, orgName]);

  const { data: enterpriseConnections, isLoading: isEnterpriseConnectionLoading } =
    enterpriseConnectionsQuery;
  const { data: users, isLoading: isUsersLoading } = usersQuery;

  if (!customer) {
    return <VtxLoadingIndicator />;
  }

  return (
    <>
      <VtxBreadcrumb>
        <VtxBreadcrumbItem>
          <Link to={generatePath(PATHS.CUSTOMER_INDEX, { orgName })}>Customer Management</Link>
        </VtxBreadcrumbItem>
        <VtxBreadcrumbItem aria-current="page" data-testid="breadcrumb-manage-customer-details">
          Manage Customer Details
        </VtxBreadcrumbItem>
      </VtxBreadcrumb>
      <VtxTitle h1Text={customer.customerName} />
      <VtxTabs index={activeTabIndex} defaultIndex={0} onChange={changeTab}>
        <VtxTabList>
          <VtxTab data-testid="customer-owned-email-domains-tab">{Tab.CustomerOwnedEmailDomains}</VtxTab>
          <VtxTab data-testid="customer-vod-subdomains-tab">{Tab.VODSubDomains}</VtxTab>
          <VtxTab data-testid="customer-users-tab">{Tab.Users}</VtxTab>
          <VtxTab data-testid="customer-connections-tab">{Tab.Connections}</VtxTab>
        </VtxTabList>
        <VtxTabPanels className="customer-tabs-panels">
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW} exact>
                <CustomerOwnedEmailDomains
                  customer={customer}
                  userInfo={userInfo}
                  emailDomains={emailDomains}
                  isLoading={!!isEmailDomainsLoading}
                />
              </Route>
            </Switch>
          </VtxTabPanel>
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW_VOD_DOMAINS} exact>
                <CustomerVodSubDomains
                  customer={customer}
                  userInfo={userInfo}
                  vodSubDomains={vodSubDomains}
                  isLoading={!!isVodSubDomainsLoading}
                />
              </Route>
            </Switch>
          </VtxTabPanel>
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW_USERS} exact>
                <CustomerUsers
                  customer={customer}
                  users={users}
                  roles={customerRoles}
                  isLoading={isUsersLoading}
                  sorting={usersSorting}
                  searchValue={usersSearchValue}
                  currentPage={usersCurrentPage}
                  pageSize={usersPageSize}
                  setSearchValue={setUsersSearchValue}
                  setSorting={setUsersSorting}
                  setCurrentPage={setUsersCurrentPage}
                  setPageSize={setUsersPageSize}
                />
              </Route>
            </Switch>
          </VtxTabPanel>
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW_CONNECTIONS} exact>
                <EnterpriseConnections
                  orgName={orgName}
                  userInfo={userInfo}
                  enterpriseConnections={enterpriseConnections}
                  isLoading={isEnterpriseConnectionLoading}
                />
              </Route>
            </Switch>
          </VtxTabPanel>
        </VtxTabPanels>
      </VtxTabs>
    </>
  );
};
