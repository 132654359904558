import { OrganizationService } from 'src/api/organizations';
import { getLocationPathname } from 'src/dom-helpers/window-wrapper';
import { IOrganization } from 'src/models/organization';
import { decodeJwt } from 'src/token-helpers/decode-jwt';

export const getOrgNameFromUrl = (): string | null => {
  const pathname = getLocationPathname();
  const segments = pathname.split('/');
  const orgName = segments[1];
  return !orgName ? null : orgName;
};

export const getOrgIdFromJwt = (accessToken: string): string | null => {
  const decodedJwt: { org_id: string } = decodeJwt(accessToken);
  return decodedJwt.org_id || null;
};

export const getOrgIdFromName = async (orgName: string): Promise<string | null> => {
  const { id } = await OrganizationService.getOrganizationByName(orgName);
  return id;
};

export const getOrgFromId = async (orgId: string): Promise<IOrganization | null> => {
  let organization: IOrganization | null = null;
  try {
    // Need to use try/catch because a bad org ID will result in a 404 response
    organization = await OrganizationService.getOrganizationById(orgId);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('No organization found for specified ID');
  }
  return organization;
};
