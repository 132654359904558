import {
  useForm,
  VtxButton,
  VtxCol,
  VtxForm,
  VtxFormItem,
  VtxInput,
  VtxModal,
  VtxMultiSelect,
  VtxRow,
  VtxSelectMultiValue,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useState } from 'react';
import { IUser, IUserAdd } from 'src/models/user';
import { IUserRole } from 'src/models/user-info';
import { FormAction } from 'src/types';

interface OwnProps {
  type: FormAction;
  selectedUser?: IUser;
  userRoles?: IUserRole[];
  onModalOk: (value: IUserAdd) => void;
  onModalCancel: () => void;
}

interface SelectOption {
  value: number;
  label: string;
}

interface IUserForm {
  emailAddress: string;
  roles: VtxSelectMultiValue<SelectOption>;
}

export const CustomerUserModal = (props: OwnProps) => {
  const { onModalOk, onModalCancel, type, selectedUser, userRoles } = props;
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const initialValues: IUserForm = {
    emailAddress: selectedUser?.user.emailAddress || '',
    roles:
      selectedUser?.roles.map((role) => {
        return { value: role.roleId, label: role.roleName };
      }) || [],
  };
  const [form] = useForm<IUserForm>(initialValues);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      form.submit();
    });
  };

  const handleCancel = () => {
    if (form.isFieldTouched('emailAddress') || form.isFieldTouched('roles')) {
      setIsFormDirty(true);
    } else if (!isFormDirty) {
      onModalCancel();
    }
  };

  const onFinish = (form: IUserForm) => {
    onModalOk({
      user: {
        emailAddress: form.emailAddress,
      },
      roles: form.roles.map((role) => {
        return { roleId: role.value, roleName: role.label };
      }),
    });
  };

  return (
    <>
      <VtxModal
        title={`${type === 'add' ? 'Add' : 'Edit'} User`}
        data-testid={`${type}-user-modal`}
        visible
        closable
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <VtxButton
            type="primary"
            data-automation-id={`${type}-modal-save-btn`}
            onClick={handleSubmit}
            key="ok"
          >
            Save
          </VtxButton>,
          <VtxButton data-automation-id={`${type}-modal-cancel-btn`} onClick={handleCancel} key="cancel">
            Cancel
          </VtxButton>,
        ]}
      >
        <p>
          {type === 'add'
            ? "Provide the user's email address, then select their role."
            : "Select the user's role."}
        </p>
        <VtxForm<IUserForm>
          form={form}
          initialValues={initialValues}
          name={`${type}-user-form`}
          onFinish={onFinish}
          validateTrigger="onBlur"
        >
          <VtxRow gutter={22}>
            {type === 'add' && (
              <VtxCol style={{ width: '50%' }}>
                <VtxFormItem<IUserForm>
                  name="emailAddress"
                  label="Email Address"
                  rules={[
                    { required: true, message: 'Email Address is required.' },
                    {
                      type: 'email',
                      message: 'Email Address must be valid.',
                    },
                  ]}
                >
                  <VtxInput data-testid="email-address" />
                </VtxFormItem>
              </VtxCol>
            )}
            <VtxCol style={{ width: '50%' }}>
              <VtxFormItem<IUserForm>
                name="roles"
                label="Role"
                rules={[{ required: true, message: 'Role is required.' }]}
              >
                <VtxMultiSelect
                  options={userRoles?.map((role) => {
                    return { value: role.roleId, label: role.roleName };
                  })}
                  data-testid="role"
                />
              </VtxFormItem>
            </VtxCol>
          </VtxRow>
        </VtxForm>
      </VtxModal>
      {isFormDirty && (
        <VtxModal
          title="Do you want to leave this form?"
          data-testid="confirmation-close-modal"
          onCancel={() => setIsFormDirty(false)}
          onOk={onModalCancel}
          cancelText="No"
          okText="Yes"
          cancelButtonProps={{ 'data-automation-id': 'confirmation-close-modal-cancel-btn' }}
          okButtonProps={{ 'data-automation-id': 'confirmation-close-modal-ok-btn' }}
          visible
        >
          Any unsaved data will be lost.
        </VtxModal>
      )}
    </>
  );
};
