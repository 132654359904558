import { VtxAlert, VtxAlertProps } from '@vertexinc/vtx-ui-react-component-library';
import React from 'react';
import 'src/components/alerts/error-alert.scss';

interface IErrorAlert {
  errors: string;
  message?: string;
  onClose: () => void;
}

export const ErrorAlert = (props: IErrorAlert) => {
  const { message = 'Correct the following errors:', errors, onClose } = props;

  let alertMessage: VtxAlertProps['message'] = message;

  if (message !== errors) {
    alertMessage = {
      headingLevel: 'h2',
      content: message,
      description: (
        <ul data-testid="error-alert-list" className="error-alert__list">
          {Array.isArray(errors) ? (
            errors.map((text) => {
              return (
                <li data-testid="error-alert-list-item" key={text}>
                  {text}
                </li>
              );
            })
          ) : (
            <li>{errors}</li>
          )}
        </ul>
      ),
    };
  }

  return (
    <VtxAlert
      data-testid="error-alert"
      message={alertMessage}
      type="error"
      closable
      onClose={onClose}
      className="error-alert"
    />
  );
};
