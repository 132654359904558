import {
  VtxAlert,
  VtxButton,
  VtxColumnProps,
  VtxSpace,
  VtxTable,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { EmailDomainsService } from 'src/api/email-domains';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { ActionConfirmationModal } from 'src/components/modals/action-confirmation-modal';
import { QUERY_KEYS } from 'src/constants';
import { ICustomer, IEmailDomain, IEmailDomainAdd } from 'src/models/customer';
import { IUserInfo } from 'src/models/user-info';
import { CustomerEmailDomainAddModal } from 'src/pages/customer-management/details/customer-owned-email-domains/customer-email-domains-add-modal';
import { SharedCustomerSearchPanel } from 'src/pages/customer-management/shared-customer-search-panel';
import { ICustomerRouteParams, IError, StatefulMessage } from 'src/types';
import { isVertexAdmin } from 'src/utils/role-helper';
import { alphabeticalSort } from 'src/utils/table-helper';

interface OwnProps {
  userInfo: IUserInfo;
  customer: ICustomer;
  emailDomains?: IEmailDomain[];
  isLoading: boolean;
}

export const CustomerOwnedEmailDomains = (props: OwnProps) => {
  const { userInfo, customer, emailDomains, isLoading } = props;
  const { customerId }: ICustomerRouteParams = useParams();
  const queryClient = useQueryClient();
  const [domainsData, setDomainsData] = useState<IEmailDomain[] | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currentDomain, setCurrentDomain] = useState<IEmailDomain>();
  const [message, setMessage] = useState<StatefulMessage>();
  const [error, setError] = useState();
  const generalErrorMessageOnAdd = 'The record could not be added.';
  const generalErrorMessageOnDelete = 'The record could not be deleted.';
  const isUserVertexAdmin = isVertexAdmin(userInfo);

  const renderAction = (id: IEmailDomain['customerId'], record: IEmailDomain) => {
    return (
      <VtxButton
        type="danger"
        data-testid={`delete-domain-${record.emailDomain}`}
        aria-label={`Delete ${record.emailDomain}`}
        onClick={() => handleDelete(record)}
        disabled={isSubmitting}
      >
        Delete
      </VtxButton>
    );
  };

  const columns: VtxColumnProps<IEmailDomain>[] = [
    {
      title: 'Email Domains',
      dataIndex: 'emailDomain',
      sorter: (a: IEmailDomain, b: IEmailDomain) => alphabeticalSort(a.emailDomain, b.emailDomain),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 250,
    },
  ];

  if (isUserVertexAdmin) {
    columns.push({
      title: 'Actions',
      dataIndex: 'customerId',
      render: renderAction,
    });
  }

  const deleteEmailDomain = useMutation(EmailDomainsService.deleteEmailDomain, {
    onSuccess: () => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setMessage({
        type: 'success',
        text: `The email domain ${currentDomain?.emailDomain} was successfully deleted.`,
      });
      setCurrentDomain(undefined);
      setSearchValue('');
      queryClient.invalidateQueries([QUERY_KEYS.EMAIL_DOMAINS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnDelete);
    },
  });

  const addEmailDomain = useMutation(EmailDomainsService.addEmailDomain, {
    onSuccess: (_, variables) => {
      setIsSubmitting(false);
      setIsAddModalVisible(false);
      setMessage({
        type: 'success',
        text: `The email domain ${variables.params.emailDomains.join()} was successfully added.`,
      });
      setSearchValue('');
      queryClient.invalidateQueries([QUERY_KEYS.EMAIL_DOMAINS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsAddModalVisible(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnAdd);
    },
  });

  const handleDelete = (domain: IEmailDomain) => {
    setIsSubmitting(true);
    setCurrentDomain(domain);
    setIsDeleteModalVisible(true);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (!value) {
      setDomainsData(emailDomains);
    } else {
      const searchResult = emailDomains?.filter((domain: IEmailDomain) => {
        return domain.emailDomain.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      setDomainsData(searchResult);
    }
  };

  const handleAdd = () => {
    setIsSubmitting(true);
    setIsAddModalVisible(true);
  };

  const onAddOk = (domain: string) => {
    const params: IEmailDomainAdd = {
      customerVersionId: customer.versionId,
      emailDomains: [domain],
    };
    addEmailDomain.mutate({ customerId, params });
  };

  const onAddClose = () => {
    setIsAddModalVisible(false);
    setIsSubmitting(false);
  };

  const onDeleteOk = () => {
    deleteEmailDomain.mutate({
      customerId,
      emailDomain: currentDomain?.emailDomain || '',
      customerVersionId: customer.versionId,
    });
  };

  const onDeleteClose = () => {
    setCurrentDomain(undefined);
    setIsDeleteModalVisible(false);
    setIsSubmitting(false);
  };

  useEffect(() => setDomainsData(emailDomains), [emailDomains]);

  return (
    <>
      <VtxSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
        {error && (
          <ErrorAlert
            errors={error}
            onClose={() => setError(undefined)}
            message={
              error === generalErrorMessageOnAdd
                ? generalErrorMessageOnAdd
                : error === generalErrorMessageOnDelete
                ? generalErrorMessageOnDelete
                : undefined
            }
          />
        )}
        {message && (
          <VtxAlert
            type={message.type}
            message={message.text}
            closable
            onClose={() => setMessage(undefined)}
            data-testid="customers-email-domains-alert"
          />
        )}
        <SharedCustomerSearchPanel
          onSearch={handleSearch}
          onActionButtonClick={handleAdd}
          actionButtonText="Add Email Domain"
          shouldDisplayActionButton={isUserVertexAdmin}
          searchValue={searchValue}
          searchAriaLabel="Search for Email Domains"
          dataTestIdSuffix="email-domains"
        />
        <VtxTable<IEmailDomain>
          rowKey="versionId"
          columns={columns}
          loading={isLoading}
          dataSource={domainsData}
          pagination={false}
          data-testid="customer-email-domains-table"
        />
      </VtxSpace>
      {isAddModalVisible && <CustomerEmailDomainAddModal onModalOk={onAddOk} onModalCancel={onAddClose} />}
      {isDeleteModalVisible && (
        <ActionConfirmationModal
          onCancel={onDeleteClose}
          onConfirm={onDeleteOk}
          title={`Do you want to delete the email domain ${currentDomain?.emailDomain}`}
          description={`Deleting the email domain ${currentDomain?.emailDomain} cannot be undone. Do you want to delete it?`}
          isVisible={isDeleteModalVisible}
        />
      )}
    </>
  );
};
