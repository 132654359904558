import { VtxLoadingIndicator } from '@vertexinc/vtx-ui-react-component-library';
import React from 'react';
import { useQuery } from 'react-query';
import { Route, Switch, useParams } from 'react-router-dom';
import { CustomerService } from 'src/api/customers';
import { EmailDomainsService } from 'src/api/email-domains';
import { VodSubDomainsService } from 'src/api/vod-sub-domains';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { ICustomer, IEmailDomain, IVodSubDomain } from 'src/models/customer';
import { IOrganization } from 'src/models/organization';
import { IUserInfo } from 'src/models/user-info';
import { EnterpriseConnectionAddPage } from 'src/pages/customer-management/details/connections/add-enterprise-connection';
import { EnterpriseConnectionEditPage } from 'src/pages/customer-management/details/connections/edit-enterprise-connection';
import { ICustomerRouteParams } from 'src/types';
import { CustomerDetailsViewPage } from './view';

interface OwnProps {
  userInfo: IUserInfo;
  organization: IOrganization;
}

const throwError = () => {
  throw new Error(
    'An error occurred retrieving customer information. Please try again and contact support if the problem persists.'
  );
};

export const CustomerDetailsPage = (props: OwnProps) => {
  const { organization, userInfo } = props;
  const { customerId }: ICustomerRouteParams = useParams();

  const customerQuery = useQuery<ICustomer, Error>(
    [QUERY_KEYS.CUSTOMER, customerId],
    () => CustomerService.getCustomer({ id: customerId }),
    {
      onError: () => {
        throwError();
      },
    }
  );

  useQuery<IVodSubDomain[], Error>(
    [QUERY_KEYS.VOD_SUBDOMAINS],
    () => VodSubDomainsService.getVodSubdomains({ customerId }),
    {
      onError: () => {
        throwError();
      },
    }
  );

  useQuery<IEmailDomain[], Error>(
    [QUERY_KEYS.EMAIL_DOMAINS],
    () => EmailDomainsService.getEmailDomains({ customerId }),
    {
      onError: () => {
        throwError();
      },
    }
  );

  useQuery(
    [QUERY_KEYS.CUSTOMER_ROLES, customerId],
    () => CustomerService.getCustomerRoles({ id: customerId }),
    {
      onError: () => {
        throwError();
      },
    }
  );

  const { data: customer, isLoading } = customerQuery;

  return (
    <>
      {!customer || isLoading ? (
        <VtxLoadingIndicator />
      ) : (
        <>
          <Switch>
            <Route path={PATHS.CONNECTIONS_ADD} exact>
              <EnterpriseConnectionAddPage organization={organization} customer={customer} />
            </Route>
            <Route path={PATHS.CONNECTIONS_EDIT} exact>
              <EnterpriseConnectionEditPage organization={organization} customer={customer} />
            </Route>
            <Route path={PATHS.CUSTOMER_VIEW}>
              <CustomerDetailsViewPage userInfo={userInfo} organization={organization} />
            </Route>
          </Switch>
        </>
      )}
    </>
  );
};
