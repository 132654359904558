import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATHS } from 'src/constants';
import { IOrganization } from 'src/models/organization';
import { IUserInfo } from 'src/models/user-info';
import { CustomerAddPage } from 'src/pages/customer-management/add/add';
import { CustomerDetailsPage } from 'src/pages/customer-management/details/details';
import { CustomerIndexPage } from 'src/pages/customer-management/index/index';

interface OwnProps {
  userInfo: IUserInfo;
  organization: IOrganization;
}

export const CustomerManagement = (props: OwnProps) => {
  const { organization, userInfo } = props;

  return (
    <Switch>
      <Route path={PATHS.CUSTOMER_INDEX} exact>
        <CustomerIndexPage userInfo={userInfo} organization={organization} />
      </Route>
      <Route path={PATHS.CUSTOMER_ADD}>
        <CustomerAddPage userInfo={userInfo} organization={organization} />
      </Route>
      <Route path={PATHS.CUSTOMER_VIEW}>
        <CustomerDetailsPage organization={organization} userInfo={userInfo} />
      </Route>
    </Switch>
  );
};
