export enum UserRole {
  EnterprisePortalAdmin = 1,
  CustomerAdmin = 2,
}

export interface IUserRole {
  roleId: number;
  roleName: string;
}

export interface IUserInfo {
  firstName?: string;
  lastName?: string;
  createdUtc?: string;
  modifiedUtc?: string;
  versionId?: string;
  isAuth0Complete?: boolean;
  roles: IUserRole[];
  userId?: string;
  emailAddress: string;
  personId?: string;
  hasAcceptedInvitation?: boolean;
}
