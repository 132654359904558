export class SessionManager {
  private static instance: SessionManager;
  private _accessToken: string | undefined;
  private _logOut: (() => Promise<void>) | undefined;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): SessionManager {
    if (!SessionManager.instance) {
      SessionManager.instance = new SessionManager();
    }

    return SessionManager.instance;
  }

  public setAccessToken(accessToken: string) {
    this._accessToken = accessToken;
  }

  public setLogOutFn(logOut: () => Promise<void>) {
    this._logOut = logOut;
  }

  public getAccessToken(): string | undefined {
    // We don't test for the access token being defined because some API calls
    // are unauthenticated.
    return this._accessToken;
  }

  public getLogOutFn(): () => Promise<void> {
    if (this._logOut == null) {
      throw new Error('Logout has not been instantiated');
    }
    return this._logOut;
  }
}
