import {
  VtxAlert,
  VtxButton,
  VtxButtonGroup,
  VtxEmpty,
  VtxIcon,
  VtxSpace,
  VtxTable,
  VtxTag,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { EnterpriseConnectionsService } from 'src/api/connections';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { ActionConfirmationModal } from 'src/components/modals/action-confirmation-modal';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { IEnterpriseConnection } from 'src/models/customer';
import { IUserInfo } from 'src/models/user-info';
import { ICustomerRouteParams, IError, StatefulMessage } from 'src/types';
import { isCustomerAdmin } from 'src/utils/role-helper';
import './enterprise-connections.scss';

interface OwnProps {
  orgName: string;
  enterpriseConnections?: IEnterpriseConnection[];
  isLoading: boolean;
  userInfo: IUserInfo;
}

const columns = [
  {
    dataIndex: 'field',
    width: '30%',
  },
  {
    dataIndex: 'value',
  },
];

const getTableData = (connection: IEnterpriseConnection) => {
  return [
    {
      key: 1,
      field: 'Connection Name',
      value: (
        <span data-testid={`connection-name-${connection.enterpriseConnectionId}`}>
          {connection.connectionName}
        </span>
      ),
    },
    {
      key: 2,
      field: 'Display Name',
      value: (
        <span data-testid={`display-name-${connection.enterpriseConnectionId}`}>
          {connection.displayName}
        </span>
      ),
    },
    {
      key: 3,
      field: 'Configuration',
      value: (
        <span data-testid={`configuration-${connection.enterpriseConnectionId}`}>
          {connection.metadataUrl ? 'Automatic Metadata' : 'Manual'}
        </span>
      ),
    },
    {
      key: 6,
      field: 'Customer-Owned Email Domains',
      value: (
        <ul data-testid={`customer-owned-email-domains-${connection.enterpriseConnectionId}`}>
          {connection.emailDomains.map((domain, index) => (
            <li key={index}>{domain}</li>
          ))}
        </ul>
      ),
    },
    {
      key: 7,
      field: 'VOD Sub-Domains',
      value: (
        <ul data-testid={`vod-sub-domains-${connection.enterpriseConnectionId}`}>
          {connection.vodSubDomains.length
            ? connection.vodSubDomains.map((domain, index) => <li key={index}>{domain}</li>)
            : 'None Selected'}
        </ul>
      ),
    },
    {
      // TODO: may need to be changed when AUTHC-1169 is done
      key: 8,
      field: 'Vertex Enterprise Portal Integration',
      value: (
        <VtxTag
          data-testid={`enterprise-sso-connection-${connection.enterpriseConnectionId}`}
          color={connection.isVertexEnterprisePortalIntegration ? 'green' : 'gold'}
        >
          {connection.isVertexEnterprisePortalIntegration ? 'On' : 'Off'}
        </VtxTag>
      ),
    },
    {
      key: 9,
      field: 'Cloud Portal Integrations',
      value: (
        <VtxTag
          data-testid={`cloud-connection-${connection.enterpriseConnectionId}`}
          color={connection.isVertexCloudIntegrated ? 'green' : 'gold'}
        >
          {connection.isVertexCloudIntegrated ? 'On' : 'Off'}
        </VtxTag>
      ),
    },
  ];
};

export const EnterpriseConnections = (props: OwnProps) => {
  const { enterpriseConnections, orgName, isLoading, userInfo } = props;
  const queryClient = useQueryClient();
  const history = useHistory();
  const { customerId }: ICustomerRouteParams = useParams();
  const location = useLocation<{ message?: StatefulMessage }>();
  const { state: locationState } = location;
  const [message, setMessage] = useState(locationState?.message);
  const [error, setError] = useState();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [currentConnection, setCurrentConnection] = useState<IEnterpriseConnection>();
  const isUserCustomerAdmin = isCustomerAdmin(userInfo);

  const generalErrorMessageOnDelete = 'The connection could not be deleted.';

  const deleteConnection = useMutation(EnterpriseConnectionsService.deleteConnection, {
    onSuccess: () => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setMessage({
        text: `The connection ${currentConnection?.displayName} was successfully deleted.`,
        type: 'success',
      });
      setError(undefined);
      queryClient.invalidateQueries([QUERY_KEYS.ENTERPRISE_CONNECTIONS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnDelete);
      setMessage(undefined);
    },
  });

  const addConnectionHandler = () => {
    history.push(generatePath(PATHS.CONNECTIONS_ADD, { orgName, customerId }));
  };
  const editConnectionHandler = (connection: IEnterpriseConnection) => {
    history.push(
      generatePath(PATHS.CONNECTIONS_EDIT, {
        orgName,
        customerId,
        connectionId: connection.enterpriseConnectionId,
      })
    );
  };

  const onDelete = (connection: IEnterpriseConnection) => {
    setIsDeleteModalVisible(true);
    setCurrentConnection(connection);
  };
  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
    setIsSubmitting(false);
  };
  const onConfirmDelete = () => {
    if (currentConnection) {
      deleteConnection.mutate({ customerId, connectionId: currentConnection.enterpriseConnectionId });
    }
  };

  const resetMessage = () => {
    setMessage(undefined);
    history.replace(location.pathname, {});
  };

  const getTableDataWithoutConnectionName = (connection: IEnterpriseConnection) => {
    return getTableData(connection).filter((el) => el.key !== 1);
  };

  return (
    <>
      {enterpriseConnections && enterpriseConnections.length === 0 && (
        <VtxEmpty description={<span>This page does not yet have any connections.</span>}>
          <VtxButton
            type="primary"
            icon={<VtxIcon type="plus" role="presentation" />}
            onClick={addConnectionHandler}
          >
            Add a Connection
          </VtxButton>
        </VtxEmpty>
      )}
      {enterpriseConnections && enterpriseConnections.length !== 0 && (
        <VtxSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
          {error && (
            <ErrorAlert
              errors={error}
              onClose={() => setError(undefined)}
              message={error === generalErrorMessageOnDelete ? generalErrorMessageOnDelete : undefined}
            />
          )}
          {message && (
            <VtxAlert
              type={message.type}
              message={message.text}
              closable
              onClose={resetMessage}
              data-testid="customer-connections-alert"
            />
          )}
          <VtxSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <VtxButton
              data-testid="customer-connections-add-button"
              type="primary"
              onClick={addConnectionHandler}
              disabled={isSubmitting}
            >
              Add Connection
            </VtxButton>
            {enterpriseConnections.map((connection: IEnterpriseConnection) => {
              // TODO: remove this condition when AUTHC fixes the possibility of creating an invalid connection that cannot be deleted
              // Only show connections that are successfully created in auth0
              if (connection.auth0ConnectionId) {
                const tableData = isUserCustomerAdmin
                  ? getTableDataWithoutConnectionName(connection)
                  : getTableData(connection);

                if (connection.metadataUrl) {
                  tableData.push({
                    key: 4,
                    field: 'Metadata URL',
                    value: (
                      <span data-testid={`metadata-url-${connection.enterpriseConnectionId}`}>
                        {connection.metadataUrl}
                      </span>
                    ),
                  });
                } else if (connection.signInUrl) {
                  tableData.push({
                    key: 4,
                    field: 'Sign In URL',
                    value: (
                      <span data-testid={`sign-in-url-${connection.enterpriseConnectionId}`}>
                        {connection.signInUrl}
                      </span>
                    ),
                  });
                  if (connection.signOutUrl) {
                    tableData.push({
                      key: 5,
                      field: 'Sign Out URL',
                      value: (
                        <span data-testid={`sign-out-url-${connection.enterpriseConnectionId}`}>
                          {connection.signOutUrl}
                        </span>
                      ),
                    });
                  }
                }

                return (
                  <div key={connection.enterpriseConnectionId}>
                    <VtxSpace
                      direction="vertical"
                      size="middle"
                      style={{ display: 'flex', marginBottom: '30px' }}
                      className="enterprise-connections"
                    >
                      <VtxTable
                        columns={columns}
                        dataSource={tableData.sort((a, b) => a.key - b.key)}
                        loading={isLoading}
                        pagination={false}
                        showHeader={false}
                        data-testid={`customer-connections-${connection.enterpriseConnectionId}-table`}
                      />
                      <VtxButtonGroup>
                        <VtxButton
                          data-testid={`customer-connections-${connection.enterpriseConnectionId}-edit-button`}
                          onClick={() => editConnectionHandler(connection)}
                          disabled={isSubmitting}
                        >
                          Edit Connection
                        </VtxButton>
                        <VtxButton
                          data-testid={`customer-connections-${connection.enterpriseConnectionId}-delete-button`}
                          type="danger"
                          onClick={() => onDelete(connection)}
                          disabled={isSubmitting}
                        >
                          Delete Connection
                        </VtxButton>
                      </VtxButtonGroup>
                    </VtxSpace>
                  </div>
                );
              }
              return null;
            })}
          </VtxSpace>

          <ActionConfirmationModal
            onCancel={onCancelDelete}
            onConfirm={onConfirmDelete}
            title={`Do you want to delete the connection ${currentConnection?.displayName}?`}
            description={`Deleting the connection ${currentConnection?.displayName} cannot be undone. Do you want to delete it?`}
            isVisible={isDeleteModalVisible}
          />
        </VtxSpace>
      )}
    </>
  );
};
