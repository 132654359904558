export const PUBLIC_APP_URL = 'https://www.vertexinc.com';
export const DEFAULT_PAGE_SIZE = 10;

export const ORGANIZATION_NAME_REGEX = /^[a-z0-9]([a-z0-9-_]+)[a-z0-9]$/;
export const VOD_SUB_DOMAIN_REGEX = /^[a-z0-9-]*$/;
export const VOD_SUB_DOMAIN_LIST_REGEX = /^([a-z0-9-][;]?)+$/;
export const EMAIL_DOMAIN_REGEX =
  /^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_DOMAIN_LIST_REGEX =
  /^(@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[;]?)+$/;

// The backend limit for the Top query is 50
export const TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50];

export const PATHS = {
  ROOT: '/',
  ORGANIZATION: '/:orgName',
  ERROR: '/error',
  NOT_FOUND: '/not-found',
  FORBIDDEN: '/access-denied',
  LOGGED_OUT_ROUTE: '/loggedout',
  OIDC_REDIRECT_RELATIVE: '/authorize',

  CUSTOMER_INDEX: '/:orgName/customers',
  CUSTOMER_ADD: '/:orgName/customers/add',
  CUSTOMER_VIEW: '/:orgName/customers/:customerId',
  CUSTOMER_VIEW_VOD_DOMAINS: '/:orgName/customers/:customerId/vod-subdomains',
  CUSTOMER_VIEW_USERS: '/:orgName/customers/:customerId/users',
  CUSTOMER_VIEW_CONNECTIONS: '/:orgName/customers/:customerId/connections',
  CONNECTIONS_ADD: '/:orgName/customers/:customerId/connections/add',
  CONNECTIONS_EDIT: '/:orgName/customers/:customerId/connections/:connectionId/edit',
};

export const QUERY_KEYS = {
  CUSTOMERS: 'customers',
  CUSTOMER: 'customer',
  CUSTOMER_ROLES: 'customerRoles',
  EMAIL_DOMAINS: 'emailDomains',
  VOD_SUBDOMAINS: 'vodSubDomains',
  ENTERPRISE_CONNECTIONS: 'enterpriseConnections',
  ENTERPRISE_CONNECTION: 'enterpriseConnection',
  USERS: 'users',
};
