import { IUserInfo, IUserRole, UserRole } from '../models/user-info';

export const isVertexAdmin = (userInfo: IUserInfo) => {
  return userInfo.roles.find((x) => x.roleId === UserRole.EnterprisePortalAdmin) != null;
};

export const isCustomerAdmin = (userInfo: IUserInfo) => {
  return userInfo.roles.find((x) => x.roleId === UserRole.CustomerAdmin) != null;
};

export const isRolesContainVertexAdmin = (roles: IUserRole[]) => {
  return roles?.find((x) => x.roleId === UserRole.EnterprisePortalAdmin) != null;
};

export const isRolesContainCustomerAdmin = (roles: IUserRole[]) => {
  return roles?.find((x) => x.roleId === UserRole.CustomerAdmin) != null;
};

export const getRoleNamesString = (roles: IUserRole[]) => {
  const rolesNames = roles.map((role) => {
    return role.roleName;
  });

  return rolesNames.join(', ');
};

export const getRoleNamesArray = (roles: IUserRole[]) => {
  return roles.map((role) => role.roleName);
};
