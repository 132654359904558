import { VtxGlobalHeader, VtxGlobalHeaderProps } from '@vertexinc/vtx-ui-react-component-library';
import { parse } from 'query-string';
import * as React from 'react';
import { OrganizationOrInvalid } from 'src/auth/authorize';
import { PUBLIC_APP_URL } from 'src/constants';
import 'src/header/header.scss';
import { UserInfo } from 'src/header/user-info';
import { IUserInfo } from 'src/models/user-info';
import { SessionManager } from 'src/session-manager';

interface OwnProps {
  userInfo: IUserInfo | null;
  organization: OrganizationOrInvalid;
  isMenuOpen: VtxGlobalHeaderProps['isMenuOpen'];
  hasSideMenu?: VtxGlobalHeaderProps['hasSideMenu'];
  toggleMenu: VtxGlobalHeaderProps['toggleMenu'];
  redirectToLogin: (orgId: string) => Promise<void>;
}

export const Header = (props: OwnProps) => {
  const { userInfo, organization, isMenuOpen, hasSideMenu, toggleMenu, redirectToLogin } = props;
  const isOrgKnown = !!organization;
  const sessionManager = SessionManager.getInstance();
  const isUserLoggedIn = userInfo !== null;

  const SKIP_TO_APP_MENU_BUTTON_TEXT = 'Skip to Application Menu';
  const SKIP_TO_MAIN_BUTTON_TEXT = 'Skip to Main Content';
  const skipLinkText = hasSideMenu ? SKIP_TO_APP_MENU_BUTTON_TEXT : SKIP_TO_MAIN_BUTTON_TEXT;
  const skipLinkUrl = hasSideMenu ? '#side-menu' : '#app-content';
  const homeLinkUrl = isOrgKnown ? `/${organization.name}` : PUBLIC_APP_URL;
  const { orgId } = parse(window.location.search);

  const logBackInButton =
    orgId && typeof orgId == 'string'
      ? { loginRedirect: () => redirectToLogin(orgId), showSkeleton: false }
      : undefined;

  const onSignOutClick = async () => {
    const logout = sessionManager.getLogOutFn();
    await logout();
  };

  return (
    <VtxGlobalHeader
      skipNavLink={{ linkText: skipLinkText, href: skipLinkUrl }}
      homeLinkHref={homeLinkUrl}
      toggleMenu={toggleMenu}
      isMenuOpen={isMenuOpen}
      hasSideMenu={hasSideMenu}
      productSwitcher="Enterprise Single Sign On"
      logBackInButton={logBackInButton}
      isUserLoggedIn={isUserLoggedIn}
      userInfo={isUserLoggedIn && <UserInfo userInfo={userInfo} logOut={onSignOutClick} />}
    />
  );
};
