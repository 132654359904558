import {
  useForm,
  VtxAlert,
  VtxButton,
  VtxCheckbox,
  VtxCheckboxGroup,
  VtxCheckboxGroupProps,
  VtxForm,
  VtxFormItem,
  VtxInput,
  VtxRadio,
  VtxRadioGroup,
  VtxRadioGroupProps,
  VtxSpace,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavigationConfirmationModal } from 'src/components/modals/navigation-confirmation-modal';
import { IEmailDomain, IVodSubDomain } from 'src/models/customer';
import { getIsDirtyForm } from 'src/pages/utils/form-helpers';

export enum Connections {
  VertexEnterprisePortal = 'Vertex Enterprise Portal Integration',
  CloudPortal = 'Cloud Portal Integrations',
}

interface OwnProps {
  formValues: IConnectionForm;
  vodSubDomains: IVodSubDomain[];
  emailDomains: IEmailDomain[];
  type?: 'add' | 'edit';
  isSubmitting: boolean;
  isLoadingEmailDomains: boolean;
  isLoadingVodSubDomains: boolean;
  onFinish: (connection: IConnectionForm) => void;
  onCancel: () => void;
}

export interface IConnectionForm {
  displayName: string;
  emailDomains: string[];
  vodSubDomains: string[];
  connections: Connections[];
  configurations?: string;
  signInUrl: string;
  signOutUrl: string;
  x509SigningCertificate: string;
  x509SigningCertificateText?: string;
  metadataUrl: string;
}

const touchFieldsForCancel: Array<keyof IConnectionForm> = [
  'displayName',
  'emailDomains',
  'configurations',
  'signInUrl',
  'metadataUrl',
];

export const SharedEnterpriseConnectionForm = (props: OwnProps) => {
  const {
    formValues,
    vodSubDomains,
    emailDomains,
    type = 'add',
    isSubmitting,
    isLoadingEmailDomains,
    isLoadingVodSubDomains,
  } = props;
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [certificateText, setCertificateText] = useState<string>('');
  const [isEmailDomainsWarningVisible, setIsEmailDomainsWarningVisible] = useState<boolean>(false);
  const [isManualConfiguration, setIsManualConfiguration] = useState<boolean | null>(null);
  const formName = `${type}-connection-form`;
  const urlRegex = /^(https:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

  const [form] = useForm<IConnectionForm>(formValues);

  const connectionsOptions = [
    { id: 'connection-vertex-sso', name: Connections.VertexEnterprisePortal },
    { id: 'connection-cloud', name: Connections.CloudPortal },
  ];
  const configurationsOptions = [
    { id: 'configuration-manual', name: 'Manual' },
    { id: 'configuration-auto-metadata', name: 'Automatic Metadata' },
  ];

  const onFinish = (values: IConnectionForm) => {
    const { metadataUrl } = values;
    setIsFormDirty(false);

    props.onFinish({
      ...values,
      x509SigningCertificate: metadataUrl
        ? ''
        : formValues.x509SigningCertificateText
        ? formValues.x509SigningCertificateText
        : certificateText,
    });
  };

  const setDirtyForm = () => {
    const isTouched = getIsDirtyForm<IConnectionForm>({ fieldArray: touchFieldsForCancel, form });
    setIsFormDirty(isTouched);
  };

  useEffect(() => {
    if (formValues.configurations) {
      setIsManualConfiguration(formValues.configurations === 'Manual');
    }
  }, []);

  const configurationsHandler: VtxRadioGroupProps['onChange'] = (event) => {
    setIsManualConfiguration(event.target.value === 'Manual');
    if (event.target.value !== 'Manual') {
      form.resetFields(['x509SigningCertificate']);
    }
  };

  const certificateFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = reader.result;
        setCertificateText(content as string);
      };
      reader.readAsText(file);
    }
  };

  const emailDomainsHandler: VtxCheckboxGroupProps['onChange'] = (checkedValues) => {
    if (type === 'edit') {
      for (let i = 0; i < formValues.emailDomains.length; i++) {
        if (!checkedValues.includes(formValues.emailDomains[i])) {
          setIsEmailDomainsWarningVisible(true);
          break;
        } else {
          setIsEmailDomainsWarningVisible(false);
        }
      }
    }
  };

  return (
    <>
      <p>
        Provide the connection name and display name, then select email domains, sub-domains, connections and
        configuration type.
      </p>
      <VtxForm<IConnectionForm>
        form={form}
        name={formName}
        data-test-id={formName}
        initialValues={formValues}
        onFinish={onFinish}
        onValuesChange={setDirtyForm}
        validateTrigger="onBlur"
      >
        <VtxSpace direction="vertical" size="large">
          <VtxSpace direction="vertical">
            <VtxFormItem<IConnectionForm>
              name="displayName"
              label="Display Name"
              rules={[
                {
                  required: true,
                  message: 'Display name is required.',
                },
                {
                  max: 50,
                  message: 'Display name length must be less than 50 characters.',
                },
              ]}
              style={{ maxWidth: '200px' }}
            >
              <VtxInput data-testid="display-name" aria-required={true} />
            </VtxFormItem>
            {!isLoadingEmailDomains && emailDomains?.length !== 0 && (
              <>
                <VtxFormItem<IConnectionForm>
                  name="emailDomains"
                  rules={[
                    {
                      required: true,
                      message: 'Customer-Owned Email Domains is required.',
                    },
                  ]}
                >
                  <VtxCheckboxGroup
                    onChange={emailDomainsHandler}
                    checkboxGroupLegend="Customer-Owned Email Domains"
                    data-testid="email-domains-group"
                  >
                    {emailDomains?.map((item: IEmailDomain) => (
                      <VtxCheckbox key={item.versionId} value={item.emailDomain}>
                        {item.emailDomain}
                      </VtxCheckbox>
                    ))}
                  </VtxCheckboxGroup>
                </VtxFormItem>
                {isEmailDomainsWarningVisible && (
                  <VtxAlert
                    type={'warning'}
                    message={'Changing domain settings removes access for anyone using the changed domain'}
                    closable
                    onClose={() => setIsEmailDomainsWarningVisible(false)}
                    data-testid="connection-email-domains-warning"
                  />
                )}
              </>
            )}
            {!isLoadingVodSubDomains && vodSubDomains?.length !== 0 && (
              <VtxFormItem<IConnectionForm> name="vodSubDomains">
                <VtxCheckboxGroup checkboxGroupLegend="VOD Sub-Domains" data-testid="vod-sub-domains-group">
                  {vodSubDomains?.map((item: IVodSubDomain) => (
                    <VtxCheckbox key={item.auth0ClientId} value={item.subDomain}>
                      {item.subDomain}
                    </VtxCheckbox>
                  ))}
                </VtxCheckboxGroup>
              </VtxFormItem>
            )}
            <VtxFormItem<IConnectionForm> name="connections">
              <VtxCheckboxGroup checkboxGroupLegend="Connections" data-testid="connections-group">
                {connectionsOptions.map((item) => (
                  <VtxCheckbox key={item.id} value={item.name} data-testid={item.id}>
                    {item.name}
                  </VtxCheckbox>
                ))}
              </VtxCheckboxGroup>
            </VtxFormItem>
            <VtxFormItem<IConnectionForm>
              name="configurations"
              rules={[
                {
                  required: true,
                  message: 'Configurations is required.',
                },
              ]}
            >
              <VtxRadioGroup
                onChange={configurationsHandler}
                radioGroupLegend="Configurations"
                data-testid="configurations-group"
              >
                {configurationsOptions.map((item) => (
                  <VtxRadio key={item.id} value={item.name} data-testid={item.id}>
                    {item.name}
                  </VtxRadio>
                ))}
              </VtxRadioGroup>
            </VtxFormItem>
            {isManualConfiguration !== null ? (
              isManualConfiguration ? (
                <>
                  <VtxSpace size="middle" data-testid="configuration-manual-content">
                    <VtxFormItem<IConnectionForm>
                      name="signInUrl"
                      label="Sign In URL"
                      rules={[
                        {
                          required: true,
                          message: 'Sign In URL is required.',
                        },
                        {
                          pattern: urlRegex,
                          message: 'Sign In URL must be a valid url.',
                        },
                      ]}
                    >
                      <VtxInput data-testid="sign-in-url" aria-required={true} />
                    </VtxFormItem>
                    <VtxFormItem<IConnectionForm>
                      name="signOutUrl"
                      label="Sign Out URL"
                      rules={[
                        {
                          pattern: urlRegex,
                          message: 'Sign Out URL must be a valid url.',
                        },
                      ]}
                    >
                      <VtxInput type="url" data-testid="sign-out-url" />
                    </VtxFormItem>
                    <VtxFormItem<IConnectionForm>
                      name="x509SigningCertificate"
                      label="x509 Signing Certificate"
                      // TODO: add instruction tag when it'll be available in the DS
                      // https://vertexinc.atlassian.net/browse/UIEN-2107?focusedCommentId=1321455
                      rules={[
                        {
                          required: !formValues.x509SigningCertificateText,
                          message: 'x509 Signing Certificate is required.',
                        },
                      ]}
                    >
                      <VtxInput
                        onChange={certificateFileHandler}
                        data-testid="signing-certificate"
                        type="file"
                        accept=".pem,.cer"
                        aria-required={true}
                      />
                    </VtxFormItem>
                  </VtxSpace>
                </>
              ) : (
                <VtxFormItem<IConnectionForm>
                  name="metadataUrl"
                  label="Metadata URL"
                  rules={[
                    {
                      required: true,
                      message: 'Metadata URL is required.',
                    },
                    {
                      pattern: urlRegex,
                      message: 'Metadata URL must be a valid url.',
                    },
                  ]}
                  style={{ maxWidth: '200px' }}
                >
                  <VtxInput data-testid="metadata-url" aria-required={true} />
                </VtxFormItem>
              )
            ) : null}
          </VtxSpace>
          <VtxSpace direction="horizontal" size="middle">
            <VtxFormItem<IConnectionForm> shouldUpdate={true}>
              <VtxButton
                data-testid="connection-save"
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
              >
                {`${type === 'add' ? 'Save' : 'Edit'} Connection`}
              </VtxButton>
            </VtxFormItem>
            <VtxFormItem<IConnectionForm>>
              <VtxButton data-testid="connection-cancel" type="default" onClick={props.onCancel}>
                Cancel
              </VtxButton>
              <NavigationConfirmationModal when={isFormDirty} />
            </VtxFormItem>
          </VtxSpace>
        </VtxSpace>
      </VtxForm>
    </>
  );
};
