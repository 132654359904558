/**
 * A simple passthrough that let's us mock this in Jest
 */
export const getLocationPathname = (): string => {
  // eslint-disable-next-line no-restricted-properties
  return window.location.pathname;
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const getLocationSearch = (): string => {
  // eslint-disable-next-line no-restricted-properties
  return window.location.search;
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const setLocationPathname = (newPathname: string): void => {
  // eslint-disable-next-line no-restricted-properties
  window.location.pathname = newPathname;
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const getLocationHref = (): string => {
  // eslint-disable-next-line no-restricted-properties
  return window.location.href;
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const setLocationHref = (newHref: string): void => {
  // eslint-disable-next-line no-restricted-properties
  window.location.href = newHref;
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const getLocationHostname = (): string => {
  // eslint-disable-next-line no-restricted-properties
  return window.location.hostname;
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const setLocationHostname = (newHostname: string): void => {
  // eslint-disable-next-line no-restricted-properties
  window.location.hostname = newHostname;
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const locationReload = (): void => {
  // eslint-disable-next-line no-restricted-properties
  window.location.reload();
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const getSearchParamValue = (param: string): string | null => {
  // eslint-disable-next-line no-restricted-properties
  const url = new URL(window.location.href);
  return url.searchParams.get(param);
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const aptrinsic = (type: string, userFields: unknown, accountFields: unknown): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).aptrinsic(type, userFields, accountFields);
};

/**
 * A simple passthrough that let's us mock this in Jest
 */
export const replaceUrl = (newUrl: string): void => {
  // eslint-disable-next-line no-restricted-properties
  window.location.replace(newUrl);
};
