import { VtxFormProps, VtxLoadingIndicator, VtxTitle } from '@vertexinc/vtx-ui-react-component-library';
import React, { useState } from 'react';
import { useIsFetching, useMutation, useQuery, useQueryClient } from 'react-query';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { EnterpriseConnectionsService } from 'src/api/connections';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { ICustomer, IEmailDomain, IEnterpriseConnection, IVodSubDomain } from 'src/models/customer';
import { IOrganization } from 'src/models/organization';
import { IConnectionRouteParams, ICustomerRouteParams, IError, StatefulMessage } from 'src/types';
import {
  Connections,
  IConnectionForm,
  SharedEnterpriseConnectionForm,
} from './shared-enterprise-connection-form';

interface OwnProps {
  customer: ICustomer;
  organization: IOrganization;
}

export const EnterpriseConnectionEditPage = (props: OwnProps) => {
  const {
    customer,
    organization: { name: orgName },
  } = props;
  const queryClient = useQueryClient();
  const isLoadingEmailDomains = !!useIsFetching(QUERY_KEYS.EMAIL_DOMAINS);
  const isLoadingVodSubDomains = !!useIsFetching(QUERY_KEYS.VOD_SUBDOMAINS);
  const { customerId, connectionId }: ICustomerRouteParams & IConnectionRouteParams = useParams();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState();
  const history = useHistory();
  const generalErrorMessageOnEdit = 'The connection could not be edited.';

  const vodSubDomains: IVodSubDomain[] = queryClient.getQueryData([QUERY_KEYS.VOD_SUBDOMAINS]) || [];
  const emailDomains: IEmailDomain[] = queryClient.getQueryData([QUERY_KEYS.EMAIL_DOMAINS]) || [];

  const connectionQuery = useQuery(
    [QUERY_KEYS.ENTERPRISE_CONNECTION, connectionId],
    () => EnterpriseConnectionsService.getConnection({ customerId, connectionId }),
    {
      onError: (err: Error) => {
        throw new Error(
          'An error occurred retrieving connection information. Please try again and contact support if the problem persists.'
        );
      },
    }
  );

  const editConnection = useMutation(EnterpriseConnectionsService.editConnection, {
    onSuccess: (connection: IEnterpriseConnection) => {
      setIsSubmitting(false);
      queryClient.invalidateQueries(QUERY_KEYS.ENTERPRISE_CONNECTIONS);
      queryClient.invalidateQueries(QUERY_KEYS.ENTERPRISE_CONNECTION);
      const message: StatefulMessage = {
        text: `The connection ${connection.displayName} was successfully edited.`,
        type: 'success',
      };
      history.push(generatePath(PATHS.CUSTOMER_VIEW_CONNECTIONS, { orgName, customerId }), { message });
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnEdit);
    },
  });

  const onFinish: VtxFormProps<IConnectionForm>['onFinish'] = (formValue) => {
    setIsSubmitting(true);
    if (connection && connectionId) {
      editConnection.mutate({
        customerId,
        connection: {
          ...formValue,
          enterpriseConnectionId: connectionId,
          versionId: connection.versionId,
          // TODO: https://vertexinc.atlassian.net/browse/UIEN-2107?focusedCommentId=1317260
          // API should generate this name, but at the moment this field is required
          connectionName: formValue.displayName,
          isVertexCloudIntegrated: formValue.connections.includes(Connections.CloudPortal),
          isVertexEnterprisePortalIntegration: formValue.connections.includes(
            Connections.VertexEnterprisePortal
          ),
        },
      });
    }
  };

  const onCancel = () => {
    history.push(generatePath(PATHS.CUSTOMER_VIEW_CONNECTIONS, { orgName, customerId }));
  };

  const getCheckedConnections = (connection: IEnterpriseConnection) => {
    const checkedConnections = [];

    if (connection.isVertexCloudIntegrated) {
      checkedConnections.push(Connections.CloudPortal);
    }
    if (connection.isVertexEnterprisePortalIntegration) {
      checkedConnections.push(Connections.VertexEnterprisePortal);
    }

    return checkedConnections;
  };

  const { data: connection, isLoading } = connectionQuery;

  if (!connection || isLoading) {
    return <VtxLoadingIndicator />;
  }

  return (
    <>
      <VtxTitle h1Text={`Edit Connection for ${customer.customerName}`} />
      {error && (
        <ErrorAlert
          errors={error}
          onClose={() => setError(undefined)}
          message={error === generalErrorMessageOnEdit ? generalErrorMessageOnEdit : undefined}
        />
      )}
      {connection && (
        <SharedEnterpriseConnectionForm
          formValues={{
            ...connection,
            x509SigningCertificate: '',
            x509SigningCertificateText: connection.x509SigningCertificate,
            connections: getCheckedConnections(connection),
            configurations: connection.metadataUrl ? 'Automatic Metadata' : 'Manual',
          }}
          vodSubDomains={vodSubDomains}
          emailDomains={emailDomains}
          type="edit"
          isSubmitting={isSubmitting}
          isLoadingEmailDomains={isLoadingEmailDomains}
          isLoadingVodSubDomains={isLoadingVodSubDomains}
          onFinish={onFinish}
          onCancel={onCancel}
        />
      )}
    </>
  );
};
