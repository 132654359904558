import { getWrappedProcessEnv } from 'src/wrapped-process-env';
import { IVodSubDomain, IVodSubDomainAdd } from 'src/models/customer';
import { request } from 'src/api/request';

const baseUrl = `${getWrappedProcessEnv().API_BASE_URL}/customers`;
const vodSubDomainsService = () => {
  return {
    getVodSubdomains: async (props: {
      customerId: string;
    }): Promise<IVodSubDomain[]> => {
      const { customerId } = props;
      return request<IVodSubDomain[]>(`${baseUrl}/${customerId}/vodsubdomains`).get();
    },
    addVodSubDomain: async (props: { customerId: string; params: IVodSubDomainAdd }): Promise<IVodSubDomain> => {
      const { customerId, params } = props;
      return request<IVodSubDomain>(`${baseUrl}/${customerId}/vodsubdomains`).post(params);
    },
    deleteVodSubDomain: async (props: {
      customerId: string;
      vodSubDomain: string;
      customerVersionId: string;
    }): Promise<null> => {
      const { customerId, vodSubDomain, customerVersionId } = props;
      return request<null>(
        `${baseUrl}/${customerId}/vodsubdomains/${vodSubDomain}/${customerVersionId}`
      ).delete();
    },
  };
};

export const VodSubDomainsService = vodSubDomainsService();
