import {
  useForm,
  VtxButton,
  VtxCol,
  VtxForm,
  VtxFormItem,
  VtxInput,
  VtxInputTextArea,
  VtxRow,
  VtxSpace,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useState } from 'react';
import { NavigationConfirmationModal } from 'src/components/modals/navigation-confirmation-modal';
import { EMAIL_DOMAIN_LIST_REGEX, ORGANIZATION_NAME_REGEX, VOD_SUB_DOMAIN_LIST_REGEX } from 'src/constants';
import { getIsDirtyForm } from 'src/pages/utils/form-helpers';

export interface ICustomerAddForm {
  customerName: string;
  organizationName: string;
  primaryContactEmailAddress: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  emailDomains: string;
  vodSubDomains: string;
}

interface OwnProps {
  formName: string;
  formValues: ICustomerAddForm;
  submitBtnTitle: string;
  isSubmitting: boolean;
  onFinish: (values: ICustomerAddForm) => void;
  onCancel: () => void;
}

const inputLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
    xl: { span: 8 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
    xl: { span: 8 },
    xxl: { span: 4 },
  },
};

const textAreaLayout = {
  labelCol: {
    ...inputLayout.labelCol,
    xxl: { span: 5 },
  },
  wrapperCol: {
    ...inputLayout.wrapperCol,
    xxl: { span: 5 },
  },
};

const touchFieldsForCancel: Array<keyof ICustomerAddForm> = [
  'customerName',
  'organizationName',
  'primaryContactEmailAddress',
  'primaryContactFirstName',
  'primaryContactLastName',
  'emailDomains',
  'vodSubDomains',
];

export const CustomerAddForm = (props: OwnProps) => {
  const { formName, formValues, submitBtnTitle, isSubmitting, onCancel } = props;
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [form] = useForm<ICustomerAddForm>(formValues);

  const setDirtyForm = () => {
    const isTouched = getIsDirtyForm<ICustomerAddForm>({ fieldArray: touchFieldsForCancel, form });
    setIsFormDirty(isTouched);
  };

  const onFinish = (values: ICustomerAddForm) => {
    setIsFormDirty(false);
    props.onFinish(values);
  };

  return (
    <VtxForm<ICustomerAddForm>
      name={formName}
      data-test-id={formName}
      form={form}
      initialValues={formValues}
      onFinish={onFinish}
      onValuesChange={setDirtyForm}
      validateTrigger="onBlur"
    >
      <VtxFormItem<ICustomerAddForm>
        name="organizationName"
        label="Organization Name:"
        rules={[
          {
            required: true,
            message: 'Organization name is required.',
          },
          {
            min: 3,
            message: 'Organization name cannot be less than 3 characters.',
          },
          {
            pattern: ORGANIZATION_NAME_REGEX,
            message: `Organization name can only contain lowercase characters, "-", and "_", and start and end with a letter or number.`,
          },
        ]}
        {...inputLayout}
      >
        <VtxInput data-testid="organization-name" aria-required={true} />
      </VtxFormItem>

      <VtxFormItem<ICustomerAddForm>
        name="customerName"
        label="Customer Name:"
        rules={[
          {
            required: true,
            message: 'Customer name is required.',
          },
        ]}
        {...inputLayout}
      >
        <VtxInput data-testid="customer-name" aria-required={true} />
      </VtxFormItem>

      <VtxFormItem<ICustomerAddForm> noStyle>
        <VtxRow style={{ columnGap: 16 }}>
          <VtxCol sm={24} md={16} lg={12} xl={8} xxl={4}>
            <VtxFormItem<ICustomerAddForm>
              name="primaryContactFirstName"
              label="Primary Contact First Name"
              rules={[
                {
                  required: true,
                  message: 'Primary contact first name is required.',
                },
              ]}
            >
              <VtxInput data-testid="customer-primary-contact-first-name" aria-required={true} />
            </VtxFormItem>
          </VtxCol>
          <VtxCol sm={24} md={16} lg={12} xl={8} xxl={4}>
            <VtxFormItem<ICustomerAddForm>
              name="primaryContactLastName"
              label="Primary Contact Last Name"
              rules={[
                {
                  required: true,
                  message: 'Primary contact last name is required.',
                },
              ]}
            >
              <VtxInput data-testid="customer-primary-contact-last-name" aria-required={true} />
            </VtxFormItem>
          </VtxCol>
        </VtxRow>
      </VtxFormItem>

      <VtxFormItem<ICustomerAddForm>
        name="primaryContactEmailAddress"
        label="Primary Contact Email Address"
        rules={[
          {
            required: true,
            message: 'Primary contact email address is required.',
          },
          {
            type: 'email',
            message: 'Email address must be valid.',
          },
        ]}
        {...inputLayout}
      >
        <VtxInput data-testid="customer-primary-contact-email" aria-required={true} />
      </VtxFormItem>

      <VtxFormItem<ICustomerAddForm>
        name="emailDomains"
        label="Customer-Owned Email Domains"
        rules={[
          {
            required: true,
            message: 'Customer-owned email domains is required.',
          },
          {
            pattern: EMAIL_DOMAIN_LIST_REGEX,
            message: `Customer-owned email domains should be in format "@example.example" and be separated with ";".`,
          },
        ]}
        {...textAreaLayout}
      >
        <VtxInputTextArea data-testid="customer-email-domains" aria-required={true} rows={5} />
      </VtxFormItem>

      <VtxFormItem<ICustomerAddForm>
        name="vodSubDomains"
        label="VOD Sub-Domains"
        rules={[
          {
            required: true,
            message: 'VOD sub-domains is required.',
          },
          {
            pattern: VOD_SUB_DOMAIN_LIST_REGEX,
            message: `The VOD sub-domain must contain only lower-case letters, numbers, or "-" and be separated with ";".`,
          },
        ]}
        {...textAreaLayout}
      >
        <VtxInputTextArea data-testid="customer-vod-sub-domains" aria-required={true} rows={5} />
      </VtxFormItem>

      <VtxSpace direction="horizontal" size="middle">
        <VtxFormItem<ICustomerAddForm> shouldUpdate={true}>
          <VtxButton data-testid="customer-save" type="primary" htmlType="submit" disabled={isSubmitting}>
            {submitBtnTitle}
          </VtxButton>
        </VtxFormItem>
        <VtxFormItem<ICustomerAddForm>>
          <VtxButton data-testid="customer-cancel" type="default" onClick={onCancel}>
            Cancel
          </VtxButton>
          <NavigationConfirmationModal when={isFormDirty} />
        </VtxFormItem>
      </VtxSpace>
    </VtxForm>
  );
};
