import { VtxConfig, VtxLoadingIndicator } from '@vertexinc/vtx-ui-react-component-library';
import { User } from 'oidc-client-ts';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'src/components/error-boundary/error-boundary';
import '../node_modules/@vertexinc/vtx-ui-react-component-library/dist/antd.min.css';
import '../node_modules/@vertexinc/vtx-ui-react-component-library/dist/vtx-ui-react-component-library.cjs.production.min.css';
import { UserInfoService } from './api/user-info';
import './app.scss';
import { authorize, OrganizationOrInvalid, UserOrRefreshingOrSessionEnded } from './auth/authorize';
import { getSignoutRedirectArgs } from './auth/get-signout-redirect-args';
import { makeUserManager } from './auth/user-manager';
import { IUserInfo } from './models/user-info';
import { Routes } from './routes';
import { SessionManager } from './session-manager';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const [organization, setOrganization] = useState<OrganizationOrInvalid>(null);
  const [user, setUser] = useState<UserOrRefreshingOrSessionEnded | null>(null);
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const userManager = makeUserManager();

  const redirectToLogin = async (orgId: string) => {
    await userManager.signinRedirect({
      extraQueryParams: {
        ...userManager.settings.extraQueryParams,
        organization: orgId,
      },
    });
  };

  useEffect(() => {
    (async function () {
      const sessionManager = SessionManager.getInstance();
      const { userOrRefreshing, organization: organizationOrInvalid } = await authorize(
        sessionManager,
        userManager
      );
      setUser(userOrRefreshing);
      setOrganization(organizationOrInvalid);

      if (userOrRefreshing instanceof User) {
        const logout = (isTimeOut?: boolean) => {
          const args = getSignoutRedirectArgs(userManager, isTimeOut, organizationOrInvalid?.id);
          return userManager.signoutRedirect(args);
        };
        sessionManager.setLogOutFn(logout);

        // Call registered renew access token callback
        userManager.events.addUserLoaded((user) => {
          sessionManager.setAccessToken(user.access_token);
        });

        const info = await UserInfoService.getUserInfo();
        setUserInfo(info);
      }

      setIsLoaded(true);
    })();
  }, []);

  let children = <VtxLoadingIndicator />;
  if (isLoaded && user != null && user != 'currentlyRefreshing') {
    children = <Routes userInfo={userInfo} organization={organization} redirectToLogin={redirectToLogin} />;
  }

  return (
    <VtxConfig identifier="vtx-ui-enterprise-portal">
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </ErrorBoundary>
    </VtxConfig>
  );
};
