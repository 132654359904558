const getOrThrowIfNotFound = (name: string): string => {
  const variable = process.env[name];
  if (variable == null) {
    throw new Error(`Environment variable ${name} not found`);
  }
  return variable.toString();
};

export const getWrappedProcessEnv = () => {
  return {
    API_BASE_URL: getOrThrowIfNotFound('API_BASE_URL'),
    OIDC_CLIENT_SETTINGS: getOrThrowIfNotFound('OIDC_CLIENT_SETTINGS'),
  };
};
