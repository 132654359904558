import {
  VtxAlert,
  VtxButton,
  VtxButtonGroup,
  VtxColumnProps,
  VtxPaginationConfig,
  VtxSorterResult,
  VtxSpace,
  VtxTable,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { UsersService } from 'src/api/users';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { ActionConfirmationModal } from 'src/components/modals/action-confirmation-modal';
import { QUERY_KEYS, TABLE_PAGE_SIZE_OPTIONS } from 'src/constants';
import { ICustomer } from 'src/models/customer';
import { PaginatedDataset } from 'src/models/paginated-dataset';
import { IUser, IUserAdd, IUserEdit } from 'src/models/user';
import { IUserRole } from 'src/models/user-info';
import { CustomerUserModal } from 'src/pages/customer-management/details/users/customer-user-modal';
import { SharedCustomerSearchPanel } from 'src/pages/customer-management/shared-customer-search-panel';
import { ICustomerRouteParams, IError, SortingDirection, StatefulMessage } from 'src/types';
import { getRoleNamesString, isRolesContainVertexAdmin } from 'src/utils/role-helper';

interface OwnProps {
  customer: ICustomer;
  users?: PaginatedDataset<IUser>;
  roles?: IUserRole[];
  isLoading: boolean;
  sorting: string;
  searchValue: string;
  currentPage: number;
  pageSize: number;
  setSearchValue: (value: string) => void;
  setSorting: (value: string) => void;
  setCurrentPage: (value: number) => void;
  setPageSize: (value: number) => void;
}

export const CustomerUsers = (props: OwnProps) => {
  const {
    customer,
    users,
    roles = [],
    isLoading,
    sorting,
    searchValue,
    currentPage,
    pageSize,
    setSearchValue,
    setSorting,
    setCurrentPage,
    setPageSize,
  } = props;
  const { customerId }: ICustomerRouteParams = useParams();
  const queryClient = useQueryClient();
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [message, setMessage] = useState<StatefulMessage>();
  const [error, setError] = useState();
  const [generalErrorMessage, setGeneralErrorMessage] = useState<string>('');

  const renderAction = (id: IUser['userId'], record: IUser) => {
    if (!isRolesContainVertexAdmin(roles) && isRolesContainVertexAdmin(record.roles)) {
      return 'Not applicable';
    }

    return (
      <VtxButtonGroup>
        <VtxButton
          data-testid={`edit-user-${id}`}
          aria-label={`Edit ${record.user.emailAddress}`}
          onClick={() => handleEdit(record)}
          disabled={isSubmitting}
        >
          Edit
        </VtxButton>
        <VtxButton
          type="danger"
          data-testid={`delete-user-${id}`}
          aria-label={`Delete ${record.user.emailAddress}`}
          onClick={() => handleDelete(record)}
          disabled={isSubmitting}
        >
          Delete
        </VtxButton>
      </VtxButtonGroup>
    );
  };

  const renderRoles = (roles: IUserRole[]) => {
    return <>{getRoleNamesString(roles)}</>;
  };

  const columns: VtxColumnProps<IUser>[] = [
    {
      title: 'Email Address',
      dataIndex: ['user', 'emailAddress'],
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      render: renderRoles,
    },
    {
      title: 'Actions',
      dataIndex: 'userId',
      render: renderAction,
    },
  ];

  const deleteUser = useMutation(UsersService.deleteUser, {
    onSuccess: () => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setMessage({
        type: 'success',
        text: `The user ${selectedUser?.user.emailAddress} was successfully deleted.`,
      });
      setSearchValue('');
      setError(undefined);
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      const generalErrorMessageOnDelete = 'The user could not be deleted.';
      setGeneralErrorMessage(generalErrorMessageOnDelete);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnDelete);
      setMessage(undefined);
    },
  });

  const addUser = useMutation(UsersService.addUser, {
    onSuccess: (_, variables) => {
      setIsSubmitting(false);
      setIsAddModalVisible(false);
      setMessage({
        type: 'success',
        text: `The user ${variables.params.user.emailAddress} was successfully created.`,
      });
      setError(undefined);
      setSearchValue('');
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsAddModalVisible(false);
      const generalErrorMessageOnAdd = 'The user could not be created.';
      setGeneralErrorMessage(generalErrorMessageOnAdd);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnAdd);
      setMessage(undefined);
    },
  });

  const editUser = useMutation(UsersService.editUser, {
    onSuccess: () => {
      setIsSubmitting(false);
      setIsEditModalVisible(false);
      setMessage({
        type: 'success',
        text: `The user ${selectedUser?.user.emailAddress} was successfully edited.`,
      });
      setError(undefined);
      setSearchValue('');
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsEditModalVisible(false);
      const generalErrorMessageOnEdit = 'The user could not be edited.';
      setGeneralErrorMessage(generalErrorMessageOnEdit);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnEdit);
      setMessage(undefined);
    },
  });

  const handleDelete = (user: IUser) => {
    setIsSubmitting(true);
    setSelectedUser(user);
    setIsDeleteModalVisible(true);
  };

  const handleEdit = (user: IUser) => {
    setIsSubmitting(true);
    setSelectedUser(user);
    setIsEditModalVisible(true);
  };

  const handleAdd = () => {
    setIsSubmitting(true);
    setIsAddModalVisible(true);
  };

  const onDeleteOk = () => {
    if (selectedUser) {
      deleteUser.mutate({
        customerId,
        userId: selectedUser?.userId,
        customerVersionId: customer.versionId,
      });
    }
  };

  const onDeleteClose = () => {
    setIsDeleteModalVisible(false);
    setIsSubmitting(false);
  };

  const onAddOk = (userData: IUserAdd) => {
    const params: IUserAdd = {
      user: { emailAddress: userData.user.emailAddress },
      roles: userData.roles,
    };
    addUser.mutate({ customerId, params });
  };

  const onAddClose = () => {
    setIsAddModalVisible(false);
    setIsSubmitting(false);
  };

  const onEditOk = (userData: IUserEdit) => {
    const params: IUserEdit = {
      roles: userData.roles,
    };
    editUser.mutate({ customerId, params });
  };

  const onEditClose = () => {
    setIsEditModalVisible(false);
    setIsSubmitting(false);
  };

  const handleTableChange = (
    pagination: VtxPaginationConfig,
    _filters: Record<string, (string | number | boolean)[] | null>,
    sorter: VtxSorterResult<IUser> | VtxSorterResult<IUser>[]
  ) => {
    const sortResult = sorter as VtxSorterResult<IUser>;

    if (Array.isArray(sortResult.field)) {
      setSorting(
        sortResult.order === SortingDirection.Descend
          ? `${sortResult.field.join('/')} ${SortingDirection.Desc}`
          : `${sortResult.field.join('/')} ${SortingDirection.Asc}`
      );
    } else {
      setSorting(
        sortResult.order === SortingDirection.Descend
          ? `${sortResult.field} ${SortingDirection.Desc}`
          : `${sortResult.field} ${SortingDirection.Asc}`
      );
    }
    setCurrentPage(pagination.current || 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting, searchValue, pageSize]);

  const paginationConfig: VtxPaginationConfig = {
    pageSize,
    current: currentPage,
    total: users?.['@odata.count'],
    pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
    onShowSizeChange: (_current: number, size: number) => setPageSize(size),
  };

  return (
    <>
      <VtxSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
        {error && (
          <ErrorAlert
            errors={error}
            onClose={() => setError(undefined)}
            message={error === generalErrorMessage ? error : generalErrorMessage}
          />
        )}
        {message && (
          <VtxAlert
            type={message.type}
            message={message.text}
            closable
            onClose={() => setMessage(undefined)}
            data-testid="customer-users-alert"
          />
        )}
        <SharedCustomerSearchPanel
          onSearch={setSearchValue}
          onActionButtonClick={handleAdd}
          actionButtonText="Add User"
          shouldDisplayActionButton={true}
          searchValue={searchValue}
          searchAriaLabel="Search for Users"
          dataTestIdSuffix="users"
        />
        <VtxTable<IUser>
          rowKey="userId"
          columns={columns}
          loading={isLoading}
          dataSource={users?.value}
          onChange={handleTableChange}
          pagination={paginationConfig}
          data-testid="customer-users-table"
        />
      </VtxSpace>
      {isAddModalVisible && (
        <CustomerUserModal userRoles={roles} type="add" onModalOk={onAddOk} onModalCancel={onAddClose} />
      )}
      {isEditModalVisible && (
        <CustomerUserModal
          type="edit"
          userRoles={roles}
          selectedUser={selectedUser}
          onModalOk={onEditOk}
          onModalCancel={onEditClose}
        />
      )}
      {isDeleteModalVisible && (
        <ActionConfirmationModal
          onCancel={onDeleteClose}
          onConfirm={onDeleteOk}
          title={`Do you want to delete the user ${selectedUser?.user.emailAddress}?`}
          description={`Deleting the user ${selectedUser?.user.emailAddress} cannot be undone. Do you want to delete it?`}
          isVisible={isDeleteModalVisible}
        />
      )}
    </>
  );
};
