import { request } from 'src/api/request';
import { IOrganization } from 'src/models/organization';
import { getWrappedProcessEnv } from 'src/wrapped-process-env';

const organizationService = () => {
  return {
    getOrganizationById: async (id: string): Promise<IOrganization> => {
      return request<IOrganization>(`${getWrappedProcessEnv().API_BASE_URL}/organizations/${id}`).get();
    },
    getOrganizationByName: async (name: string): Promise<{ id: string | null }> => {
      return request<{ id: string }>(
        `${getWrappedProcessEnv().API_BASE_URL}/organizations/name/${name}`
      ).get();
    },
  };
};

export const OrganizationService = organizationService();
