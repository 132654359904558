import { SignoutRedirectArgs, UserManager } from 'oidc-client-ts';

export const getSignoutRedirectArgs = (
  userManager: Readonly<UserManager>,
  isTimeOut?: boolean,
  orgId?: string
): SignoutRedirectArgs => {
  const { post_logout_redirect_uri, extraQueryParams } = userManager.settings;

  if (!post_logout_redirect_uri) {
    throw new Error('Could not find post_logout_redirect_uri on the client settings. It is required.');
  }
  const postLogoutRedirectUrl = new URL(post_logout_redirect_uri);

  if (isTimeOut != null) {
    postLogoutRedirectUrl.searchParams.append('timeout', 'true');
  }

  if (orgId != null) {
    postLogoutRedirectUrl.searchParams.append('orgId', orgId);
  }

  /**
   * The OIDC spec states that you can specify a post-logout redirect URL at run-time by defining a post_logout_redirect_uri
   * parameter. Auth0 has decided to not support this, but rather use a "proprietary" returnTo query parameter instead.  Because
   * of this, and to allow for the timeout and orgId query string parameters to be passed to the loggedout page, we need to define
   * both post_logout_redirect_uri and extraQueryParams.returnTo in our signout redirect args object.
   */
  return {
    post_logout_redirect_uri: postLogoutRedirectUrl.toString(),
    extraQueryParams: {
      ...extraQueryParams,
      returnTo: postLogoutRedirectUrl.toString(),
    },
  };
};
