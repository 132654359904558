import { VtxButton, VtxModal } from '@vertexinc/vtx-ui-react-component-library';
import React from 'react';

interface IActionConfirmationModal {
  title: string;
  description: string;
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ActionConfirmationModal = (props: IActionConfirmationModal) => {
  const { title, description, isVisible, onConfirm, onCancel } = props;

  return (
    <VtxModal
      title={title}
      data-testid="action-confirmation-modal"
      onCancel={onCancel}
      onOk={onConfirm}
      visible={isVisible}
      footer={[
        <VtxButton data-automation-id="action-confirmation-modal__ok-btn" onClick={onConfirm} key="Yes">
          Yes
        </VtxButton>,
        <VtxButton data-automation-id="action-confirmation-modal__cancel-btn" onClick={onCancel} key="No">
          No
        </VtxButton>,
      ]}
    >
      {description}
    </VtxModal>
  );
};
