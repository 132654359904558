import { VtxIcon } from '@vertexinc/vtx-ui-react-component-library';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { PATHS } from 'src/constants';
import { IOrganization } from 'src/models/organization';
import { IUserInfo } from 'src/models/user-info';
import { isVertexAdmin } from 'src/utils/role-helper';
import './side-menu.scss';

interface OwnProps {
  userInfo: IUserInfo;
  organization: IOrganization;
}

export const SideMenu = (props: OwnProps) => {
  const {
    organization: { name: orgName },
    userInfo,
  } = props;

  return (
    <div id="side-menu" className="side-menu" data-testid="side-menu">
      <ul>
        <li>
          <Link
            to={generatePath(PATHS.CUSTOMER_INDEX, { orgName })}
            role="menuitem"
            data-testid="customer-management"
          >
            <VtxIcon type="building" /> Customer Management
          </Link>
          {isVertexAdmin(userInfo) && (
            <ul>
              <li>
                <Link to={generatePath(PATHS.CUSTOMER_ADD, { orgName })} role="menuitem">
                  Invite a Customer
                </Link>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};
