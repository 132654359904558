import { OidcClientSettings } from 'oidc-client-ts';

export const isOidcClientSettings = (arg: unknown): arg is OidcClientSettings => {
  return (
    typeof arg === 'object' &&
    arg != null &&
    'authority' in arg &&
    'client_id' in arg &&
    'response_type' in arg &&
    'redirect_uri' in arg &&
    'scope' in arg &&
    'extraQueryParams' in arg &&
    'audience' in arg['extraQueryParams']
  );
};
