import { VtxFormProps, VtxTitle } from '@vertexinc/vtx-ui-react-component-library';
import React, { useState } from 'react';
import { useIsFetching, useMutation, useQueryClient } from 'react-query';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { EnterpriseConnectionsService } from 'src/api/connections';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { ICustomer, IEmailDomain, IEnterpriseConnection, IVodSubDomain } from 'src/models/customer';
import { IOrganization } from 'src/models/organization';
import {
  Connections,
  IConnectionForm,
  SharedEnterpriseConnectionForm,
} from 'src/pages/customer-management/details/connections/shared-enterprise-connection-form';
import { ICustomerRouteParams, IError, StatefulMessage } from 'src/types';

interface OwnProps {
  customer: ICustomer;
  organization: IOrganization;
}

const defaultConnection: IConnectionForm = {
  displayName: '',
  emailDomains: [],
  vodSubDomains: [],
  connections: [],
  configurations: '',
  signInUrl: '',
  signOutUrl: '',
  x509SigningCertificate: '',
  metadataUrl: '',
};

export const EnterpriseConnectionAddPage = (props: OwnProps) => {
  const {
    customer,
    organization: { name: orgName },
  } = props;
  const queryClient = useQueryClient();
  const isLoadingEmailDomains = !!useIsFetching(QUERY_KEYS.EMAIL_DOMAINS);
  const isLoadingVodSubDomains = !!useIsFetching(QUERY_KEYS.VOD_SUBDOMAINS);
  const { customerId }: ICustomerRouteParams = useParams();
  const history = useHistory();
  const [error, setError] = useState();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const generalErrorMessageOnAdd = 'The connection could not be created.';

  const vodSubDomains: IVodSubDomain[] = queryClient.getQueryData([QUERY_KEYS.VOD_SUBDOMAINS]) || [];
  const emailDomains: IEmailDomain[] = queryClient.getQueryData([QUERY_KEYS.EMAIL_DOMAINS]) || [];

  const addConnection = useMutation(EnterpriseConnectionsService.addConnection, {
    onSuccess: (connection: IEnterpriseConnection) => {
      setIsSubmitting(false);
      queryClient.invalidateQueries(QUERY_KEYS.ENTERPRISE_CONNECTIONS);
      const message: StatefulMessage = {
        text: `The new connection ${connection.displayName} was successfully created.`,
        type: 'success',
      };
      history.push(generatePath(PATHS.CUSTOMER_VIEW_CONNECTIONS, { orgName, customerId }), { message });
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessageOnAdd);
    },
  });

  const onFinish: VtxFormProps<IConnectionForm>['onFinish'] = (formValue) => {
    setIsSubmitting(true);
    addConnection.mutate({
      customerId,
      connection: {
        ...formValue,
        // TODO: https://vertexinc.atlassian.net/browse/UIEN-2107?focusedCommentId=1317260
        // API should generate this name, but at the moment this field is required
        connectionName: formValue.displayName,
        isVertexCloudIntegrated: formValue.connections.includes(Connections.CloudPortal),
        isVertexEnterprisePortalIntegration: formValue.connections.includes(
          Connections.VertexEnterprisePortal
        ),
      },
    });
  };

  const onCancel = () => {
    history.push(generatePath(PATHS.CUSTOMER_VIEW_CONNECTIONS, { orgName, customerId }));
  };

  return (
    <>
      <VtxTitle h1Text={`Add Connection for ${customer.customerName}`} />
      {error && (
        <ErrorAlert
          errors={error}
          onClose={() => setError(undefined)}
          message={error === generalErrorMessageOnAdd ? generalErrorMessageOnAdd : undefined}
        />
      )}
      <SharedEnterpriseConnectionForm
        formValues={defaultConnection}
        vodSubDomains={vodSubDomains}
        emailDomains={emailDomains}
        type="add"
        isSubmitting={isSubmitting}
        isLoadingEmailDomains={isLoadingEmailDomains}
        isLoadingVodSubDomains={isLoadingVodSubDomains}
        onFinish={onFinish}
        onCancel={onCancel}
      />
    </>
  );
};
