import { request } from 'src/api/request';
import { IUserInfo } from 'src/models/user-info';
import { getWrappedProcessEnv } from 'src/wrapped-process-env';

const baseUrl = `${getWrappedProcessEnv().API_BASE_URL}/users/userinfo`;

const userInfoService = () => {
  return {
    getUserInfo: async (): Promise<IUserInfo> => {
      return request<IUserInfo>(baseUrl).get();
    },
  };
};

export const UserInfoService = userInfoService();
