import {
  VtxAlert,
  VtxButton,
  VtxButtonGroup,
  VtxColumnProps,
  VtxLoadingIndicator,
  VtxPaginationConfig,
  VtxSorterResult,
  VtxSpace,
  VtxTable,
  VtxTag,
  VtxTitle,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { CustomerService } from 'src/api/customers';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { ActionConfirmationModal } from 'src/components/modals/action-confirmation-modal';
import { DEFAULT_PAGE_SIZE, PATHS, QUERY_KEYS, TABLE_PAGE_SIZE_OPTIONS } from 'src/constants';
import { replaceUrl } from 'src/dom-helpers/window-wrapper';
import { ICustomer } from 'src/models/customer';
import { IOrganization } from 'src/models/organization';
import { PaginatedDataset } from 'src/models/paginated-dataset';
import { IUserInfo } from 'src/models/user-info';
import { SharedCustomerSearchPanel } from 'src/pages/customer-management/shared-customer-search-panel';
import { IError, SortingDirection, StatefulMessage } from 'src/types';
import { isVertexAdmin } from 'src/utils/role-helper';

interface OwnProps {
  userInfo: IUserInfo;
  organization: IOrganization;
}

const GENERIC_ERROR_MESSAGE = 'The customer could not be deleted.';

export const CustomerIndexPage = (props: OwnProps) => {
  const {
    organization: { name: orgName },
    userInfo,
  } = props;
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [deleteModalData, setDeleteModalData] = useState<ICustomer>();
  const location = useLocation<{ message?: StatefulMessage } | undefined>();
  const { state: locationState } = location;
  const [message, setMessage] = useState(locationState?.message);
  const [error, setError] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sorting, setSorting] = useState<string>(`customerName ${SortingDirection.Asc}`);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const isUserVertexAdmin = isVertexAdmin(userInfo);

  const customers = useQuery<PaginatedDataset<ICustomer>, Error>(
    [QUERY_KEYS.CUSTOMERS, currentPage, pageSize, sorting, searchValue],
    () => CustomerService.getCustomers({ currentPage, pageSize, orderBy: sorting, search: searchValue }),
    {
      onError: (err: Error) => {
        throw new Error(
          'An error occurred retrieving customer information. Please try again and contact support if the problem persists.'
        );
      },
      enabled: isUserVertexAdmin,
    }
  );

  const deleteCustomer = useMutation(CustomerService.deleteCustomer, {
    onSuccess: () => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setMessage({
        type: 'success',
        text: `The customer ${deleteModalData?.customerName} was successfully deleted.`,
      });
      setError(undefined);
      queryClient.invalidateQueries(QUERY_KEYS.CUSTOMERS);
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setError(err.cause?.response?.data.errorMessages || GENERIC_ERROR_MESSAGE);
      setMessage(undefined);
    },
  });

  const renderStatus = (status: ICustomer['isAuth0Complete']) => {
    return <VtxTag color={status ? 'green' : 'gold'}>{status ? 'Complete' : 'Incomplete'}</VtxTag>;
  };

  const renderAction = (id: ICustomer['customerId'], record: ICustomer) => {
    return (
      <VtxButtonGroup>
        <VtxButton
          data-testid={`customer-view-link-${id}`}
          aria-label={`View ${record.customerName}`}
          onClick={() => onView(id)}
        >
          View
        </VtxButton>
        <VtxButton
          type="danger"
          data-testid={`customer-delete-link-${id}`}
          aria-label={`Delete ${record.customerName}`}
          onClick={() => onDelete(record)}
          disabled={isSubmitting}
        >
          Delete
        </VtxButton>
      </VtxButtonGroup>
    );
  };

  const columns: VtxColumnProps<ICustomer>[] = [
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Auth0 Configuration Status',
      dataIndex: 'isAuth0Complete',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: renderStatus,
    },
    {
      title: 'Action',
      dataIndex: 'customerId',
      render: renderAction,
    },
  ];

  const handleTableChange = (
    pagination: VtxPaginationConfig,
    _filters: Record<string, (string | number | boolean)[] | null>,
    sorter: VtxSorterResult<ICustomer> | VtxSorterResult<ICustomer>[]
  ) => {
    const sortResult = sorter as VtxSorterResult<ICustomer>;

    setSorting(
      sortResult.order === SortingDirection.Descend
        ? `${sortResult.field} ${SortingDirection.Desc}`
        : `${sortResult.field} ${SortingDirection.Asc}`
    );
    setCurrentPage(pagination.current || 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting, searchValue, pageSize]);

  const paginationConfig: VtxPaginationConfig = {
    pageSize,
    current: currentPage,
    total: customers.data?.['@odata.count'],
    pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
    onShowSizeChange: (_current: number, size: number) => setPageSize(size),
  };

  const onView = (customerId: string) => {
    history.push(generatePath(PATHS.CUSTOMER_VIEW, { orgName, customerId }));
  };

  const onDelete = (customer: ICustomer) => {
    setIsDeleteModalVisible(true);
    setDeleteModalData(customer);
  };

  const onCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const onConfirmDelete = () => {
    setIsSubmitting(true);

    if (deleteModalData) {
      deleteCustomer.mutate({
        customerId: deleteModalData.customerId,
        versionId: deleteModalData.versionId,
      });
    }
  };

  const onAdd = () => history.push(generatePath(PATHS.CUSTOMER_ADD, { orgName }));

  const resetError = () => {
    setError(undefined);
  };

  const resetMessage = () => {
    setMessage(undefined);
    history.replace(location.pathname, {});
  };

  if (!isUserVertexAdmin) {
    replaceUrl(PATHS.FORBIDDEN);
    return <VtxLoadingIndicator />;
  }

  return (
    <VtxSpace
      data-testid="customer-index-page"
      direction="vertical"
      size="middle"
      style={{ display: 'flex' }}
    >
      <VtxTitle h1Text="Customer Management" />
      {message && (
        <VtxAlert
          data-testid="customer-index-page-alert"
          message={message.text}
          type={message.type}
          closable
          onClose={resetMessage}
        />
      )}
      {error && <ErrorAlert errors={error} message={GENERIC_ERROR_MESSAGE} onClose={resetError} />}
      <SharedCustomerSearchPanel
        searchValue={searchValue}
        onSearch={setSearchValue}
        onActionButtonClick={onAdd}
        shouldDisplayActionButton={isUserVertexAdmin}
        actionButtonText="Invite a Customer"
        searchAriaLabel="Search for customers"
        dataTestIdSuffix="customers"
      />
      <VtxTable<ICustomer>
        rowKey="customerId"
        columns={columns}
        data-testid="customer-management-table"
        dataSource={customers.data?.value}
        loading={customers.isLoading}
        onChange={handleTableChange}
        pagination={paginationConfig}
      />

      <ActionConfirmationModal
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        title={`Do you want to delete the customer ${deleteModalData?.customerName}?`}
        description={`Deleting the customer ${deleteModalData?.customerName} cannot be undone. Do you want to delete it?`}
        isVisible={isDeleteModalVisible}
      />
    </VtxSpace>
  );
};
