import React from 'react';
import { PATHS } from 'src/constants';
import { setLocationHref } from 'src/dom-helpers/window-wrapper';

interface OwnState {
  hasRenderError: boolean;
}

export class ErrorBoundary extends React.Component<unknown, OwnState> {
  state = {
    hasRenderError: false,
  };

  static getDerivedStateFromError() {
    return { hasRenderError: true };
  }

  render() {
    if (this.state.hasRenderError) {
      setLocationHref(PATHS.ERROR);
      return null;
    }
    return this.props.children;
  }
}
